import React, { Component } from 'react';
import { DataContext } from '../../context/DataContextProvider';
import {API_URL} from '../../constant/ApiLink'
import axios from 'axios'
import spinning_pill from '../../images/pill.png'
import { Modal, ModalHeader,ModalBody, ModalFooter,Badge} from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let sameSearch = 0; 
let columns = [
  {label: 'Year/Month',field: 'date',sort: 'asc'},{label: 'Region',field: 'county_name',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},{label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},{label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'}, 
  ];

  let columnMonthlyNation = [
    {label: 'Year/Month',field: 'date',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},{label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},{label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'}, 
  ];

  let columnsYearlyRegional = [
    {label: 'Year',field: 'date',sort: 'asc'},{label: 'Region',field: 'county_name',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},{label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},{label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'}, 
  ];

  let columnsYearlyNational = [
    {label: 'Year',field: 'date',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},{label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},{label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'}
  ];
  

  let notSortable = [
   //'age_group', 'demographic_size'
  ]
  const faLoading = <FontAwesomeIcon icon={faSpinner} spin  />
let modalBody = "";
let modalHeader = "";
let previousSort = ""
class Product extends Component {
    static contextType = DataContext
    constructor(props){
        super(props);
        this.state={
            token:"",
            searchTerm:{},
            data: [],
            consolidatedData : [],
            consolidationLoaded:1,
            totalRowsFound:0,
            clickedSearch : this.props.clickedSearch,
            loading:true,
            showEmptyModal:false,
            page:1,
            rowsInPage:0,
            unauthorizedModal:false,
            showMoreLoading:1,
            sorting:false

        }

        this.checkIfSameSearchString.bind(this)
        this.checkIfEmptySearch.bind(this)
        this.toogleModal.bind(this)
        this.showmore.bind(this)
        this.showTimeOut.bind(this)
        this.sortTable.bind(this)
        this.doNothing.bind(this)
        
    }

    showTimeOut=()=>{
      localStorage.removeItem('drugstatsSearchTOne');
      localStorage.removeItem('drugstatsSearchTTwo');
      localStorage.removeItem('username');
      localStorage.removeItem('userCompany');
      localStorage.removeItem('token');
      document.location.href="/";    
    }

    doNothing=(e)=>{
      e.preventDefault();
      return;
    }

    sortTable=(e)=>{
      this.setState({
        sorting:true
      })
      let search = localStorage.getItem('drugstatsSearchTTwo');       
      let searchTerm = JSON.parse(search);      
      let initialDirection =  this.state.orderDirection;
      if(searchTerm.orderByTwo !==""){
        previousSort = searchTerm.orderByTwo;
        initialDirection = searchTerm.orderTwo;
      }

      let orderBy = "";
      let initialSort = previousSort;
      if(e.currentTarget.id !== ''){
        orderBy = e.currentTarget.id;
      }
      previousSort = orderBy;
      let orderDirection = "";
      if(initialSort !== orderBy && initialDirection !==''){
        orderDirection = 'asc'; 
      }else{
        if(initialDirection === ''){
          orderDirection = 'asc'        
        }else if(initialDirection === 'asc'){
          orderDirection = 'desc'        
        }else if(initialDirection === 'desc'){
          orderDirection = ''                
        }
      }
      this.setState({orderBy:orderBy,orderDirection:orderDirection})
     
     
      let seacrhObj = this.state.searchTerm;  
      seacrhObj.orderDirection = orderDirection;
      searchTerm.orderByTwo = orderBy;
      searchTerm.orderTwo = orderDirection;
      searchTerm.showMore = 0;
      let seacrObject = JSON.stringify(searchTerm);
      localStorage.setItem('drugstatsSearchTTwo', seacrObject);    
      if(orderDirection !== ''){      
        axios.post(`${API_URL}getDrugStatsSubstitutions`, {
          seacrhObj: seacrObject
          }).then(response=>{
  
            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                              
              
            let rowsInPage = response.data.data.length;
            this.setState({
              data: response.data.data,
              totalRowsFound:response.data.total_row[0].total_row,
              rowsInPage:rowsInPage,
              loading:0,
              sorting:false                
            })
            this.props.context.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'product');
            }else if(response.status === 200 && response.data.message === 'Empty Search'){
              this.setState({
                showEmptyModal:true,
                loading:0
              })
            }else if(response.status === 200 && response.data.message === 'Parallel login'){
              this.setState({
                unauthorizedModal: !this.state.unauthorizedModal,          
              })
            }
            
            
          }).catch( (err) => {
            //document.location.href="/";
            //  console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)                
            console.log(err)
          })
        

      }else{
        // let previousDirection = this.state.orderDirection;
        // let prevElement = document.getElementById(previousSort+'_'+previousDirection);
        // prevElement.classList.add("hidden");

        // let removeHeaderHighlight = document.getElementById(previousSort);
        // removeHeaderHighlight.classList.remove("contract-price");
        // for(let i=0; i<totalEntries; i++){
        //   let dataCell = document.getElementById(i+'_'+previousSort);
        //   dataCell.classList.remove("contract-price");
        // }
        
        // seacrhObj.orderBy  = '';
        // seacrhObj.orderDirection = ''; 
        this.setState({
          data: this.props.context.data.dataProductRaw,
          totalRowsFound:this.props.context.data.productTotal,
          rowsInPage:this.props.context.data.productInPage,
          loading:0,
          sorting:false                
        })
      }
     
      
    }

    showmore=(event)=>{
      event.preventDefault();
      this.setState({
        showMoreLoading:0
      })
      let toTalPage = 0
      if(this.state.totalRowsFound>0){
        let res = this.state.totalRowsFound/500
        toTalPage = Math.ceil(res)
      }
      if(this.state.page<toTalPage){
        let newPageNo = this.state.page+1
        this.setState({
          page:newPageNo
        })
        let search = localStorage.getItem('drugstatsSearchTTwo');     
        let searchTerm = JSON.parse(search);
        searchTerm.page = newPageNo;
        searchTerm.showMore = 1;
        let seacrObject = JSON.stringify(searchTerm);
        localStorage.setItem('drugstatsSearchTTwo', seacrObject); 
        axios.post(`${API_URL}getDrugStatsSubstitutions`, {
          seacrhObj: seacrObject
          },{
            headers:{
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.getItem('token')
            }           
          }).then(response=>{  
            
            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
              let arrayCombined = [...this.state.data,...response.data.data];
              this.setState({
                data: arrayCombined,
                totalRowsFound:response.data.total_row[0].total_row,
                rowsInPage:arrayCombined.length,
                loading:0,
                showMoreLoading:1
                
            })
            this.props.context.updateData(arrayCombined,response.data.total_row[0].total_row, arrayCombined.length, 'product');
            this.props.context.updateData(arrayCombined,response.data.total_row[0].total_row, arrayCombined.length, 'product_raw');
            }else if(response.status === 200 && response.data.message === 'Empty Search'){
              this.setState({
                showEmptyModal:true,
                loading:0
              })
            }else if(response.status === 200 && response.data.message === 'Parallel login'){
              this.setState({
                unauthorizedModal: !this.state.unauthorizedModal,          
              })
            }
            
            
          }).catch( (err) => {
            //document.location.href="/";                
            let error = err.response;
            console.log(error)
          })
        }
        
      }

      
    

    toogleModal=()=>{
      this.setState({
        showEmptyModal:!this.state.showEmptyModal
      })
    }

    checkIfEmptySearch=(searchObj)=>{
      let searchTerm = JSON.parse(searchObj)
      // /console.log(searchObj)
      let keys = Object.keys(searchTerm)
      let arrayCount = 0;
      let emptyCount = 0;
      for(let key of keys){
        if(typeof searchTerm[key] === 'object'){
          if(searchTerm[key] === null){
            emptyCount++;
          }else{
            arrayCount++;
          }
        }else{
          emptyCount++;
        }
      }
      return arrayCount;
    }
    checkIfSameSearchString=(str1, str2)=>{
        let sameString = true;
        let keys = Object.keys(str2)
        for (let key of keys) {
          let t1 = !(key in str1)? "":str1[key];
          let t2 = !(key in str2)? "":str2[key];
          if( JSON.stringify(t1).trim() !== JSON.stringify(t2).trim()){
            return false;
          }        
        }
        return sameString;
    }
    componentDidMount(){
      
      let search = localStorage.getItem('drugstatsSearchTTwo')     
      let searchTerm = JSON.parse(search)      
      if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
        if(Object.keys(searchTerm).length === 0 && searchTerm.constructor === Object) {
          if(this.state.clickedSearch === 1 ){
            this.setState({
              showEmptyModal:true,
              loading:0
            })
          }
        }else{
          if(Object.keys(searchTerm).length > 0){
            let seacrObject = JSON.stringify(searchTerm);
            
            let filledTerm = this.checkIfEmptySearch(seacrObject);
            if(filledTerm>0){
              
              if(this.state.clickedSearch){
                let value = this.context;
                let productDataLength = Object.keys(value.data.dataProduct).length;
                
                
                if(!productDataLength && value.data.requestTwoSent === 1){
                  let search = localStorage.getItem('drugstatsSearchTTwo');
                  let seacrObject = JSON.stringify(searchTerm);
                  //console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)
                  axios.post(`${API_URL}getDrugStatsSubstitutions`, {
                    seacrhObj: seacrObject
                    },{
                      headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+localStorage.getItem('token')
                      }           
                    }).then(response=>{  
                      
                      if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                        let rowsInPage = response.data.data.length;
                        this.setState({
                          data: response.data.data,
                          totalRowsFound:response.data.total_row[0].total_row,
                          rowsInPage:rowsInPage,
                          loading:0,
                          
                      })
                      localStorage.setItem('totalProd', response.data.total_row[0].total_row); 
                      this.props.context.updateData(response.data.data, response.data.total_row[0].total_row, rowsInPage, 'product');
                      this.props.context.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'product_raw');
                      axios.post(`${API_URL}getSubsConsolidated`, {
                        seacrhObj: seacrObject
                      }).then(response=>{ 
                       
                        if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                          
                          this.setState({
                            consolidatedData: response.data.consolidatedData[0],
                            consolidationLoaded:1     
                          })
                          this.props.context.updateData(response.data.consolidatedData[0], "", "", 'consolidatedData');
                        }
                      })
                      }else if(response.status === 200 && response.data.message === 'Empty Search'){
                        this.setState({
                          showEmptyModal:true,
                          loading:0
                        })
                      }else if(response.status === 200 && response.data.message === 'Parallel login'){
                        this.setState({
                          unauthorizedModal: !this.state.unauthorizedModal,          
                        })
                      }
                    })
                }else{
                  
                  this.setState({
                    data: value.data.dataProduct,
                    totalRowsFound:value.data.productTotal,
                    rowsInPage:value.data.productInPage,
                    consolidatedData : value.data.consolidatedData,
                    consolidationLoaded:value.data.consolidationLoaded,
                    loading:0,                         
                  })
                }
                
              }
            }else{
              if(this.state.clickedSearch === 1 ){
                this.setState({
                  showEmptyModal:true,
                  loading:0
                })
              }
            }
           
          }
        }
      }
      
     
        
    }
    componentDidUpdate(prevProps, prevState){
      let searchTerm = this.props.searchTerm;
      let oldSearchTerm = prevProps.searchTerm;
        //let epmtySerach = (searchTerm === null)? true:false;
        let epmtySerach = (Object.keys(searchTerm).length === 0 && searchTerm.constructor === Object)? true:false;
        if(!epmtySerach){
            sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
            if(!sameSearch){
                let searchT = this.props.searchTerm; 
                this.setState({
                    searchTerm:searchT,
                    clickedSearch:1,
                    loading:1
                })
                
                searchT.page = this.state.page;
                let seacrObject = JSON.stringify(searchT);
                // console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)
                axios.post(`${API_URL}getDrugStatsSubstitutions`, {
                    seacrhObj: seacrObject
                  },{
                    headers:{
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer '+localStorage.getItem('token')
                    }           
                  }).then(response=>{              
                    if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                      let rowsInPage = response.data.data.length;
                      this.setState({
                        data: response.data.data,
                        totalRowsFound:response.data.total_row[0].total_row,
                        rowsInPage:rowsInPage,
                        loading:0,
                        
                    })
                    localStorage.setItem('totalProd', response.data.total_row[0].total_row); 
                    this.props.context.updateData(response.data.data, response.data.total_row[0].total_row, rowsInPage, 'product');
                    this.props.context.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'product_raw');
                    axios.post(`${API_URL}getSubsConsolidated`, {
                      seacrhObj: seacrObject
                    }).then(response=>{ 
                      
                      if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                        
                        this.setState({
                          consolidatedData: response.data.consolidatedData[0],
                          consolidationLoaded:1     
                        })
                        this.props.context.updateData(response.data.consolidatedData[0], "", "", 'consolidatedData');
                      }
                    })


                    }else if(response.status === 200 && response.data.message === 'Empty Search'){
                      this.setState({
                        showEmptyModal:true,
                        loading:0
                      })
                    }else if(response.status === 200 && response.data.message === 'Parallel login'){
                      this.setState({
                        unauthorizedModal: !this.state.unauthorizedModal,          
                      })
                    }
                  }).catch(function (error) {
                    if (error.response) {
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    }
                  });
                
            }
        
        }
    }
    render() {
      
        const {clickedSearch,rowsInPage,totalRowsFound, page,showMoreLoading,consolidatedData,consolidationLoaded} = this.state
        let search = localStorage.getItem('drugstatsSearchTTwo')
        let searchTerm = JSON.parse(search)
        let orderBy = "";
        let orderDirection = "";
        let showPerMonth = true;
        let showRegion = true;
        if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
          showPerMonth = searchTerm.selectedPerMonth;
          showRegion = searchTerm.selectedRegionWise;
          orderBy = searchTerm.orderByTwo;
          orderDirection = searchTerm.orderTwo;  
        }
        
        let activeColumns = [];
        
        if(showPerMonth && showRegion){
          activeColumns = columns;
        }else if(!showPerMonth && showRegion){
          activeColumns = columnsYearlyRegional;
        }else if(!showPerMonth && !showRegion){
          activeColumns = columnsYearlyNational;
        }else if(showPerMonth && !showRegion){
          activeColumns = columnMonthlyNation;
        }
        
        let renderPagination,renderTableBody,renderTableHeader,renderProductTable,renderTableBottom,showMoreLink;
        let showSpinningPill = true;
        
        
        if(totalRowsFound>0){
         
          let formatTotalRow = totalRowsFound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          let formatRownInPage = rowsInPage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); 

            renderPagination=(
              <div className="pagination_style">
                <div className="product_counter">
                    {
                      (rowsInPage>0? 'Showing '+(formatRownInPage+' of '+ formatTotalRow +''+(totalRowsFound>1?" records":" record") +" found") :"") 
                    
                    }
                    
                    {
                      (
                        showMoreLoading ?
                        <a href="#" onClick={rowsInPage<totalRowsFound?this.showmore:()=>{}} style={{marginRight:"10px", color:"#e76226"}}className={rowsInPage<totalRowsFound? "show float_right":"hide "}>Show more</a>
                        :<span style={{color:"#e76226",marginRight:"10px"}}className="float_right">loading...</span>
                      )
                    }
                    
                </div>
              </div>
              
            )
          } 
          if(totalRowsFound>0){
            renderTableBottom = (
              <tfoot>
                  <tr>
                    <td colSpan={showRegion?"10":"9"}>&nbsp;</td>
                    <td className="text-right">{consolidatedData? isNaN(consolidatedData.prescription_count)? "" : new Intl.NumberFormat('sv-SE').format( consolidatedData.prescription_count ): " "}</td>
                    <td className="text-right">{consolidatedData? isNaN(consolidatedData.substitutions)? "" : new Intl.NumberFormat('sv-SE').format( consolidatedData.substitutions ): " "}</td>
                  </tr>
              </tfoot>
            )
           
            if(showRegion){
              renderTableBody = ( 
                <tbody>
                  {this.state.data.map((row, index) => {
                   
                    return (
                      <tr className="product_row" key={index} id={index}>
                          <td id={index+'_date'} className={orderBy === 'date' && orderDirection !== ''?"text-right contract-price":"text-right"}>{row.date}</td>
                          <td id={index+'_county_name'} className={orderBy === 'county_name' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.county_name}</td>  
                          <td id={index+'_product'} className={orderBy === 'product' && orderDirection !== ''?"text-left contract-price":"text-left"} >{row.product}</td>
                          <td id={index+'_atc_code'} className={orderBy === 'atc_code' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.atc_code}</td>                        
                          <td id={index+'_substance'} className={orderBy === 'substance' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.substance}</td>
                          <td id={index+'_form'} className={orderBy === 'form' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.form}</td>
                          <td id={index+'_age_group'} className={orderBy === 'age_group' && orderDirection !== ''?"text-right contract-price":"text-right"}>{(row.age_group+"" ==='0')?'0-18':'19+'}</td>
                          <td id={index+'_gender'} className={orderBy === 'gender' && orderDirection !== ''?"text-left contract-price":"text-left"}>{(row.gender+"" === "0")?'Kvinnor':'Man'}</td>
                          <td id={index+'_specialization_description'} className={orderBy === 'specialization_description' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.specialization_description}</td>
                          <td id={index+'_first_substitution_date'} className={orderBy === 'first_substitution_date' && orderDirection !== ''?"text-right contract-price":"text-right"}>{row.first_substitution_date}</td>
                          
                          <td id={index+'_prescription_count'} className={orderBy === 'prescription_count' && orderDirection !== ''?"text-right contract-price":"text-right"} >
                            {row.prescription_count? isNaN(row.prescription_count)? "" : new Intl.NumberFormat('sv-SE').format( row.prescription_count): " "}
                          </td>
                          <td id={index+'_substitutions'} className={orderBy === 'substitutions' && orderDirection !== ''?"text-right contract-price":"text-right"}>
                            {row.substitutions? isNaN(row.substitutions)? "" : new Intl.NumberFormat('sv-SE').format( row.substitutions): " "}
                          </td>
                      
                      </tr>
                    );
                  })}
                  
                </tbody> 
              );
            }else{
              renderTableBody = ( 
                <tbody>
                  {this.state.data.map((row, index) => {
                    
                    return (
                      <tr className="product_row" key={index} id={index}>
                          <td id={index+'_date'} className={orderBy === 'date' && orderDirection !== ''?"text-right contract-price":"text-right"}>{row.date}</td>
                          <td id={index+'_product'} className={orderBy === 'product' && orderDirection !== ''?"text-left contract-price":"text-left"} >{row.product}</td>                        
                          <td id={index+'_atc_code'} className={orderBy === 'atc_code' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.atc_code}</td>                        
                          <td id={index+'_substance'} className={orderBy === 'substance' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.substance}</td>
                          <td id={index+'_form'} className={orderBy === 'form' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.form}</td>
                          <td id={index+'_age_group'} className={orderBy === 'age_group' && orderDirection !== ''?"text-right contract-price":"text-right"}>{(row.age_group+"" ==='0')?'0-18':'19+'}</td>
                          <td id={index+'_gender'} className={orderBy === 'gender' && orderDirection !== ''?"text-left contract-price":"text-left"}>{(row.gender+"" === "0")?'Kvinnor':'Man'}</td>
                          <td id={index+'_specialization_description'} className={orderBy === 'specialization_description' && orderDirection !== ''?"text-left contract-price":"text-left"}>{row.specialization_description}</td>
                          <td id={index+'_first_substitution_date'} className={orderBy === 'first_substitution_date' && orderDirection !== ''?"text-right contract-price":"text-right"}>{row.first_substitution_date}</td>
                          
                          <td id={index+'_prescription_count'} className={orderBy === 'prescription_count' && orderDirection !== ''?"text-right contract-price":"text-right"} >
                            {row.prescription_count? isNaN(row.prescription_count)? "" : new Intl.NumberFormat('sv-SE').format( row.prescription_count): " "}
                          </td>
                          <td id={index+'_substitutions'} className={orderBy === 'substitutions' && orderDirection !== ''?"text-right contract-price":"text-right"}>
                            {row.substitutions? isNaN(row.substitutions)? "" : new Intl.NumberFormat('sv-SE').format( row.substitutions): " "}
                          </td>
                          
                      
                      </tr>
                    );
                  })}
                  
                </tbody> 
              );
            }
            
          }
       
          renderTableHeader = (
            <tr>
              {activeColumns.map((suggestion, index) => {
                let sortingView = (
                  <>  <span  style={{marginRight:"5px"}} id={suggestion.field+'_loading'} className={(orderBy===suggestion.field && this.state.sorting)?"":"hidden" }>{faLoading}</span></>
                  );
                  let sortedView = (
                    <>
                      <span id={suggestion.field+'_asc'} className={(orderDirection === 'asc' && orderBy===suggestion.field)? 'asc':'asc hidden' }>▲</span>
                        <span id={suggestion.field+'_desc'} className={(orderDirection === 'desc'&& orderBy===suggestion.field)? 'desc':'desc hidden' }>▼</span>
                    </>
                  )
                return (
                  <th key={index} id={suggestion.field} 
                    className = {(suggestion.field === orderBy && orderDirection !== "")?' clickable contract-price': 'clickable' }
                    onClick = {this.sortTable}
                  >     
                    {suggestion.label}
                    &nbsp;
                    {
                        this.state.sorting ? sortingView:sortedView
                    }                        
                  </th>
                );
              })}
            </tr>
          );
        
       
         

        if(clickedSearch === 1 ){
              
          showSpinningPill = true;
          if(this.state.loading && !this.state.showEmptyModal){
              renderProductTable = (
                  <div className={(showSpinningPill) ? 'spinning_pill': 'spinning_pill_hidden'}>
                      <img src={spinning_pill} alt="spinning_pill" />
                  </div>              
              )
              // showMoreLink = (
              //   <div>&nbsp;</div>
              // )
          }else if (this.state.loading && this.state.showEmptyModal){
            renderProductTable = (
              <div className="no_data_found">Empty search parameters</div>            
            )
          }else{
            if(this.state.data.length > 0 ){
                showSpinningPill = false;
                renderProductTable = (
                    <table id="productTable">
                      <thead>
                        {renderTableHeader}
                      </thead>      
                      {renderTableBody}
                      {renderTableBottom}                
                    </table>
                )
            }else{
              renderProductTable = (
                <div className="no_data_found">No records found</div>            
              )
            }
              
              
          }
        }else if(clickedSearch === 0 ){
          showSpinningPill = false;
              renderProductTable = (
                  <div className="no_data_found">Search for drugstats</div>            
              )
        }
        
        return ( 
            <React.Fragment>
                 <div>
                    <div id="main-table-wrapper" style={{overflowX:"scroll"}}>
                        {renderPagination}
                        {renderProductTable}
                    </div>
                    {/* <div>
                      {showMoreLink}
                    </div> */}
                    <div style={{width:'100vw'}}>
                    <Modal style=  {{maxWidth: '500px', width: '500px'}}  isOpen={this.state.showEmptyModal} toggle={this.toogleModal}>
                        <ModalHeader  toggle={this.toogleModal} charCode="x" className="modal_title">Empty search parameters for this tab!</ModalHeader>
                        <div className="form_alert">
                            <p> You must choose at least one search parameter from these options: </p>
                            <div className="option_detail">
                            <ul className="modal_option_names">
                            <li>Product</li>
                            <li>Substance</li>
                            <li>ATC code</li>
                            <li>Year</li>
                            <li>Month</li>
                            <li>Gender</li>
                            <li>Age group</li>
                            <li>Region</li>
                            <li>Healthcare facility</li>

                            </ul>
                            </div>
                            
                        
                        </div>
                        <ModalFooter> 
                          <button onClick={this.toogleModal} type="button" className="form-button">Ok</button>                 
                        </ModalFooter>
                      </Modal>
                    </div>

                    <div style={{width:'50vw'}}>
                        <Modal size="lg" isOpen={this.state.unauthorizedModal}>
                          <ModalHeader  charCode="x" className="modal_title">Session Missmatched, you have been logged out!</ModalHeader>
                          <ModalBody className="modalBody">
                            The same account is not allowed to be used on different devices simultaneously.                            
                          </ModalBody>
                          <ModalFooter> 
                            <button onClick={this.showTimeOut} type="button" className="form-button">Ok</button>                 
                          </ModalFooter>
                        </Modal>
                  </div>
                </div>
            </React.Fragment>
         );
    }
}
const withContext = (Component) => {
   
    return(props)=>(
      <DataContext.Consumer>{(context)=>{
         //console.log(context)
          return ( 
              <Component {...props} context={context} />
          );
      }}
      </DataContext.Consumer>
    )
      
  
}
export default withContext(Product);