import React, { Component } from 'react';
import { DataContext } from '../../context/DataContextProvider';
import {API_URL} from '../../constant/ApiLink'
import axios from 'axios'
import spinning_pill from '../../images/pill.png'
import { Modal, ModalHeader,ModalBody, ModalFooter,Badge} from 'reactstrap';
import { Chart } from "react-google-charts";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let sameSearch = 0; 
//{label: 'Est. sales value',field: 'est_sales_val',sort: 'asc'}
//let columns = [{label: 'Year',field: 'year',sort: 'asc'}, {label: 'Region',field: 'county_name',sort: 'asc'}, {label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'}, {label: 'Age group',field: 'age_group',sort: 'asc'}, {label: 'Gender',field: 'gender',sort: 'asc'}, {label: 'Pop.',field: 'demographic_size',sort: 'asc'}, {label: 'Pa.c.',field: 'patient_count',sort: 'asc'},{label: 'Pa.c./1000',field: 'patient_count_t',sort: 'asc'},{label: 'Pr.c.',field: 'prescription_count',sort: 'asc'},{label: 'Est. sales value',field: 'est_sales_val',sort: 'asc'},{label: 'Pr.c. / 1000' ,field: 'prescription_count_t',sort: 'asc'},{label: 'DDD c.' ,field: 'ddd_count',sort: 'asc'},{label: 'DDD c. / 1000 / Day' ,field: 'ddd_count_t_d',sort: 'asc'},{label: 'DDD c. / 1000' ,field: 'ddd_count_t',sort: 'asc'}];
let columns = [{label: 'Year',field: 'year',sort: 'asc'}, {label: 'Region',field: 'county_name',sort: 'asc'}, {label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'}, {label: 'Age group',field: 'age_group',sort: 'asc'}, {label: 'Gender',field: 'gender',sort: 'asc'}, {label: 'Pop.',field: 'demographic_size',sort: 'asc'}, {label: 'Pa.c.',field: 'patient_count',sort: 'asc'},{label: 'Pa.c./1000',field: 'patient_count_t',sort: 'asc'},{label: 'Pr.c.',field: 'prescription_count',sort: 'asc'},{label: 'Est. sales value',field: 'est_sales_val',sort: 'asc'},{label: 'Pr.c. / 1000' ,field: 'prescription_count_t',sort: 'asc'}];
let columnsNationWide = [{label: 'Year',field: 'year',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'}, {label: 'Age group',field: 'age_group',sort: 'asc'}, {label: 'Gender',field: 'gender',sort: 'asc'}, {label: 'Pop.',field: 'demographic_size',sort: 'asc'}, {label: 'Pa.c.',field: 'patient_count',sort: 'asc'},{label: 'Pa.c./1000',field: 'patient_count_t',sort: 'asc'},{label: 'Pr.c.',field: 'prescription_count',sort: 'asc'},{label: 'Est. sales value',field: 'est_sales_val',sort: 'asc'},{label: 'Pr.c. / 1000' ,field: 'prescription_count_t',sort: 'asc'}];

const formatConfig = {
  //style: "currency",
  //currency: "SEK", // kr is showing instead of SEK that is why commented out
  
  style: "decimal",
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const faLoading = <FontAwesomeIcon icon={faSpinner} spin  />
let notSortable = [
  //'age_group', 'demographic_size'
]
let previousSort = ""

const options = {
  title: "",
  fontName: "Roboto, sans-serif",
  chartArea: {
    right: 40,
    left: 80
  },
  height: '350px',
  pointSize: 6,
  pointShape: 'circle',
  legend: { position: "none" },
    hAxis: {
      title: 'Year',
      curveType: 'function',
      titleTextStyle: {
        fontSize: 14,
        bold: true,
        italic: false
      }
    },
    vAxis: {
      title: 'Prescription and patient count',
      titleTextStyle: {
        fontSize: 14,
        bold: true,
        italic: false
      },
      minValue: 0,
      gridlines: {
        color: 'transparent'
      },
    },
  
};

class Substance extends Component {
    static contextType = DataContext
    constructor(props){
        super(props);
        this.state={
            token:"",
            searchTerm:{},
            data: [],
            totalRowsFound:0,
            clickedSearch : this.props.clickedSearch,
            loading:true,
            showEmptyModal:false,
            page:1,
            rowsInPage:0,
            unauthorizedModal:false,
            chartModal:false,
            singleDataChartModal:false,
            showMoreLoading:1,
            sumData:[],
            ungrouperdData:[],
            sumDataLoaded:0,
            ungrouperdDataLoaded:0,
            orderBy : "",
            orderDirection:"",
            chartData:"",
            individualChartData:"",
            chartSubstance:"",
            chartRegion:"",
            chartAge:"",
            chartGender:"",
            chartSingleSubstance:"",
            chartSingleRegion:"",
            chartSingleAge:"",
            chartSingleGender:"",
            sorting:false            
        }

        this.checkIfSameSearchString.bind(this)
        this.checkIfEmptySearch.bind(this)
        this.toogleModal.bind(this)
        this.showmore.bind(this)
        this.showTimeOut.bind(this)
        this.renderChart.bind(this)
        this.renderSingleDataChart.bind(this)
        this.loadChartData.bind(this)
        this.loadChartText.bind(this)
        this.sortTable.bind(this)
        
        
    }
    doNothing=(e)=>{
      e.preventDefault();
      return;
    }


    sortTable=(e)=>{
      this.setState({
        sorting:true
      })

      let search = localStorage.getItem('drugstatsSearchTOne');       
      let searchTerm = JSON.parse(search);      
      let initialDirection =  this.state.orderDirection;
      if(searchTerm.orderByOne !==""){
        previousSort = searchTerm.orderByOne;
        initialDirection = searchTerm.orderOne;
      }

      let orderBy = "";
      let initialSort = previousSort;
      if(e.currentTarget.id !== ''){
        orderBy = e.currentTarget.id;
      }
      previousSort = orderBy;
      let orderDirection = "";
      if(initialSort !== orderBy && initialDirection !==''){
        orderDirection = 'asc'; 
      }else{
        if(initialDirection === ''){
          orderDirection = 'asc'        
        }else if(initialDirection === 'asc'){
          orderDirection = 'desc'        
        }else if(initialDirection === 'desc'){
          orderDirection = ''                
        }
      }
      this.setState({orderBy:orderBy,orderDirection:orderDirection})
      
      let seacrhObj = this.state.searchTerm;
      seacrhObj.orderDirection = orderDirection; 
      searchTerm.orderByOne = orderBy;
      searchTerm.showMoreSubstance = 0;
      searchTerm.orderOne = orderDirection;
      let seacrObject = JSON.stringify(searchTerm);
      localStorage.setItem('drugstatsSearchTOne', seacrObject);      
      if(orderDirection !== ''){
        axios.post(`${API_URL}getDrugstats`, {
        seacrhObj: seacrObject}).then(response=>{
          if(response.status === 200 && response.data.message === 'Drugstats loaded!'){
          let rowsInPage = response.data.data.length;
          this.setState({
            data: response.data.data,
            totalRowsFound:response.data.total_row[0].total_row,
            rowsInPage:rowsInPage,
            loading:0,
            sorting:false              
          })
          this.props.context.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'substance');
          }else if(response.status === 200 && response.data.message === 'Empty Search'){
            this.setState({
              showEmptyModal:true,
              loading:0
            })
          }else if(response.status === 200 && response.data.message === 'Parallel login'){
            this.setState({
              unauthorizedModal: !this.state.unauthorizedModal,          
            })
          }
        }).catch( (err) => {
          //document.location.href="/";                
          console.log(err)
        }) 

      }else{
        this.setState({
          data: this.props.context.data.dataSubstanceRaw,
          totalRowsFound:this.props.context.data.substanceTotal,
          rowsInPage:this.props.context.data.substanceInPage,
          loading:0,
          sorting:false              
        })
      }
      
    }
    loadChartData=()=>{
      if(this.state.sumDataLoaded && this.state.sumData.length>0){
        let chartData = [["Year","Patient Count", "Prescription Count"]]
        this.state.sumData.map((row, index) => {
            chartData.push([""+row.year+"",parseInt(row.patient_count), parseInt(row.prescription_count)])
        })
        this.setState({
          chartData:chartData
        })
      }

    }
    loadChartText=(substanceText = "", regionText = "", ageText= "", genderText= "", haveText=0)=>{
      if(haveText === 0){
        if(Object.keys(this.state.searchTerm).length !== 0 && this.state.searchTerm.constructor === Object){
          let substanceText = "";
          let AtcText = "";
          let tGText = "";
          let substanceTextWithTG = ""
          let hasTherapyGroup = 0;
          if(this.state.searchTerm.selectedTherapyGroup !==""){
            tGText = this.state.searchTerm.selectedTherapyGroup.label;
          }
          if(this.state.searchTerm.selectedSubstance.length>0){
            if(this.state.searchTerm.selectedSubstance.length < 3 && this.state.searchTerm.selectedSubstance.length > 1 ){
              for (let index = 0; index < this.state.searchTerm.selectedSubstance.length; index++) {
                if(index === this.state.searchTerm.selectedSubstance.length-1){
                  substanceText += this.state.searchTerm.selectedSubstance[index].label;
                }else{
                  substanceText += this.state.searchTerm.selectedSubstance[index].label+'; ';
                }
              }
              
            }else if(this.state.searchTerm.selectedSubstance.length === 1){
              substanceText += this.state.searchTerm.selectedSubstance[0].label;
            }
            else{
              substanceText = 'Multiple substances';
            }
            if(this.state.searchTerm.selectedAtc.length>0){
              if(this.state.searchTerm.selectedAtc.length < 3 && this.state.searchTerm.selectedAtc.length > 1 ){
                for (let index = 0; index < this.state.searchTerm.selectedAtc.length; index++) {
                  if(index === this.state.searchTerm.selectedAtc.length-1){
                    AtcText += this.state.searchTerm.selectedAtc[index].label;
                  }else{
                    AtcText += this.state.searchTerm.selectedAtc[index].label+'; ';
                  }
                }
                
              }else if(this.state.searchTerm.selectedAtc.length === 1){
                AtcText += this.state.searchTerm.selectedAtc[0].label;
              }else{
                AtcText = 'Multiple ATC Codes';
              }

            }
            
            
          }else{
            if(this.state.searchTerm.selectedAtc.length < 3 && this.state.searchTerm.selectedAtc.length > 1 ){
              for (let index = 0; index < this.state.searchTerm.selectedAtc.length; index++) {
                if(index === this.state.searchTerm.selectedAtc.length-1){
                  substanceText += this.state.searchTerm.selectedAtc[index].label;
                }else{
                  substanceText += this.state.searchTerm.selectedAtc[index].label+'; ';
                }
              }
              
            }else if(this.state.searchTerm.selectedAtc.length === 1){
              substanceText += this.state.searchTerm.selectedAtc[0].label;
            }else{
              substanceText = 'Multiple ATC Codes';
            }

          }
          substanceTextWithTG = (substanceText === "") ? tGText: ((tGText === "")? substanceText:tGText+' & '+substanceText);            
          this.setState({
            chartSubstance : substanceTextWithTG +((AtcText !=="")? '; '+AtcText:"")
          })

          let regionText = "";
         if(this.state.searchTerm.selectedRegion.length >=1){
          for (let index = 0; index < this.state.searchTerm.selectedRegion.length; index++) {
            if(index === this.state.searchTerm.selectedRegion.length-1){
              regionText += this.state.searchTerm.selectedRegion[index].label;
            }else{
              regionText += this.state.searchTerm.selectedRegion[index].label+', ';
            }
          }
         }else{
          regionText = "All regions";
         }
         this.setState({
          chartRegion : regionText,          
         })

         let ageText = "";
         if(this.state.searchTerm.selectedAgeGroup.length >=1){
          
          for (let index = 0; index < this.state.searchTerm.selectedAgeGroup.length; index++) {
            if(index === this.state.searchTerm.selectedAgeGroup.length-1){
              ageText += this.state.searchTerm.selectedAgeGroup[index].label;
            }else{
              ageText += this.state.searchTerm.selectedAgeGroup[index].label+', ';
            }
          }
         }else{
          ageText = "All age groups";
         }
         
         this.setState({
          chartAge : ageText,
          
        })

         let genderText = "";
         if(this.state.searchTerm.selectedGender.length+"" ==="1"){
          
          genderText += this.state.searchTerm.selectedGender[0].label;
          
         }else if(this.state.searchTerm.selectedGender.length+"" === "2" || this.state.searchTerm.selectedGender.length+"" === "0"){
          genderText = "Both genders";
        }
        
        this.setState({
          chartGender : genderText,
          
        })
        }
      }else{
        
        this.setState({
          chartSingleSubstance:substanceText,
          chartSingleRegion:regionText,
          chartSingleAge:ageText,
          chartSingleGender:genderText,
          
        })
      }
      

    }
    renderChart=()=>{
      this.loadChartData();
      this.loadChartText();
      this.setState({
        chartModal:!this.state.chartModal
      })
    }
    renderSingleDataChart=(e, id=0, singleRow={})=>{
      let rowRegion = singleRow.county_name
      if(id !== 0){
        let  obj = {
          diagnoseId:id,
          singleStatsInDetail:1
        }
        let seacrhObj = JSON.stringify(obj);
        // 
        if(!this.state.searchTerm.selectedRegionWise){
          let seacrhTerm = this.state.searchTerm;
          seacrhTerm.ungrouperd = 0;
          seacrhTerm.ungrouperdGender = Number(singleRow.gender);          
          
          let rowsSearchObject = {
            'selectedSubstance':[{"value":singleRow.substance_id,"label":singleRow.substance}],
            'selectedAtc': [{"value":singleRow.atc_code,"label":singleRow.atc_code}],
            'selectedRegion': "",
            'selectedAgeGroup': [{"value":singleRow.age_group_id,"label":singleRow.age_group}],
            'selectedGender':[{"value":singleRow.gender,"label":singleRow.gender}],
            'selectedYearsList':[{"value":singleRow.year,"label":singleRow.year}],
            'selectedTherapyGroup':"",
            'ungrouperd':0,
            'nationalRow':1
          };
          let seacrhObj = JSON.stringify(rowsSearchObject);
          axios.post(`${API_URL}getConsolidate`, {
            seacrhObj: seacrhObj
            },{
              headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
              }           
            }).then(response=>{  
            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
              // console.log(response.data.data)
              let chartData = [["Year","Patient Count", "Prescription Count"]]
              let substanceText = singleRow.substance+' ('+singleRow.atc_code+')';;
              let ageText = singleRow.age_group;
              let genderText = (singleRow.gender+""==="0")? "Kvinnor":"Man";
              let regionText = "All regions";
              response.data.consolidatedData.map((row, index) => {
                chartData.push([""+row.year+"",parseInt(row.patient_count), parseInt(row.prescription_count)])
              })
              this.setState({
                individualChartData:chartData,              
                singleDataChartModal:!this.state.singleDataChartModal
              })
              this.loadChartText(substanceText, regionText, ageText, genderText, 1)
            }else if(response.status === 200 && response.data.message === 'Parallel login'){
              this.setState({
                unauthorizedModal: !this.state.unauthorizedModal,          
              })
            } 
          })

        }else{

              
          
          axios.post(`${API_URL}getDrugstatsSingle`, {
            seacrhObj: seacrhObj
            },{
              headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
              }           
            }).then(response=>{  
            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
              // console.log(response.data.data)
              let chartData = [["Year","Patient Count", "Prescription Count"]]
              let substanceText = "";
              let regionText = "";
              let ageText = "";
              let genderText = "";
              response.data.data.map((row, index) => {
                chartData.push([""+row.year+"",parseInt(row.patient_count), parseInt(row.prescription_count)])
                substanceText = row.substance+' ('+row.atc_code+')';
                regionText = (!this.state.searchTerm.selectedRegionWise)? "All regions":rowRegion;                
                ageText = row.age_group;
                genderText = (row.gender+""==="0")? "Kvinnor":"Man";
              })
              this.setState({
                individualChartData:chartData,              
                singleDataChartModal:!this.state.singleDataChartModal
              })
              this.loadChartText(substanceText, regionText, ageText, genderText, 1)
            }else if(response.status === 200 && response.data.message === 'Parallel login'){
              this.setState({
                unauthorizedModal: !this.state.unauthorizedModal,          
              })
            }
              
          })
        }
        
        
      }else{
        this.setState({
          singleDataChartModal:!this.state.singleDataChartModal
        })
      }
      e.preventDefault();
      
    }

    showTimeOut=()=>{
      localStorage.removeItem('drugstatsSearchTOne');
      localStorage.removeItem('drugstatsSearchTTwo');
      localStorage.removeItem('username');
      localStorage.removeItem('userCompany');
      localStorage.removeItem('token');
      document.location.href="/";    
    }

    showmore=(event)=>{
      event.preventDefault();
      this.setState({
        showMoreLoading:0
      })
      let toTalPage = 0
      if(this.state.totalRowsFound>0){
        let res = this.state.totalRowsFound/500
        toTalPage = Math.ceil(res)
      }
      if(this.state.page<toTalPage){
        let newPageNo = this.state.page+1
        this.setState({
          page:newPageNo
        })
        let search = localStorage.getItem('drugstatsSearchTOne');     
        let searchTerm = JSON.parse(search);
        searchTerm.page = newPageNo;
        searchTerm.showMoreSubstance = 1;
        let seacrObject = JSON.stringify(searchTerm);
        let noOfMoreRow = 0
        localStorage.setItem('drugstatsSearchTOne', seacrObject); 
        axios.post(`${API_URL}getDrugstats`, {
          seacrhObj: seacrObject
          },{
            headers:{
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.getItem('token')
            }           
          }).then(response=>{              
            

            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
              let arrayCombined = [...this.state.data,...response.data.data];
              this.setState({
                data: arrayCombined,
                totalRowsFound:response.data.total_row[0].total_row,
                rowsInPage:arrayCombined.length,
                loading:0,
                showMoreLoading:1
                
            })
            noOfMoreRow = response.data.total_row[0].total_row
            this.props.context.updateData(arrayCombined,response.data.total_row[0].total_row, arrayCombined.length, 'substance');
            this.props.context.updateData(arrayCombined,response.data.total_row[0].total_row, arrayCombined.length, 'substance_raw');
            }else if(response.status === 200 && response.data.message === 'Empty Search'){
              this.setState({
                showEmptyModal:true,
                loading:0
              })
            }else if(response.status === 200 && response.data.message === 'Parallel login'){
              this.setState({
                unauthorizedModal: !this.state.unauthorizedModal,          
              })
            }
          }).catch( (err) => {
            //document.location.href="/";                
            console.log(err)
          })
        
        if(this.state.orderBy!=="" && this.state.orderDirection !==""){
          
          
        }
      //    
      
      
      // 
        }
      }

    toogleModal=()=>{
      this.setState({
        showEmptyModal:!this.state.showEmptyModal
      })
    }

    checkIfEmptySearch=(searchObj)=>{
      let searchTerm = JSON.parse(searchObj)
      // /console.log(searchObj)
      let keys = Object.keys(searchTerm)
      let arrayCount = 0;
      let emptyCount = 0;
      for(let key of keys){
        if(typeof searchTerm[key] === 'object'){
          if(searchTerm[key] === null){
            emptyCount++;
          }else{
            arrayCount++;
          }
        }else{
          emptyCount++;
        }
      }
      return arrayCount;
    }
    checkIfSameSearchString=(str1, str2)=>{
        let sameString = true;
        let keys = Object.keys(str2)
        for (let key of keys) {
          let t1 = !(key in str1)? "":str1[key];
          let t2 = !(key in str2)? "":str2[key];
          if( JSON.stringify(t1).trim() !== JSON.stringify(t2).trim()){
            return false;
          }        
        }
        return sameString;
    }
    componentDidMount(){
      let search = localStorage.getItem('drugstatsSearchTOne')
      let searchTerm = JSON.parse(search)
      if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
        if(Object.keys(searchTerm).length === 0 && searchTerm.constructor === Object) {
          if(this.state.clickedSearch === 1 ){
            previousSort = searchTerm.orderByOne
            this.setState({
              showEmptyModal:true,
              loading:0,
              orderDirection:searchTerm.orderOne
            })
          }
        }else{
          
          if(Object.keys(searchTerm).length > 0){
            let seacrObject = JSON.stringify(searchTerm);
            
            let filledTerm = this.checkIfEmptySearch(seacrObject);
            if(filledTerm>0){
              
              if(this.state.clickedSearch){
                let value = this.context;
                let substanceDataLength = Object.keys(value.data.dataSubstance).length;
                               
                if(!substanceDataLength && value.data.requestOneSent === 1){
                  let seacrObject = JSON.stringify(searchTerm);
                  // console.log(`${API_URL}getDrugstats?seacrhObj=${seacrObject}`)
                  axios.post(`${API_URL}getDrugstats`, {
                    seacrhObj: seacrObject
                    },{
                      headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+localStorage.getItem('token')
                      }           
                    }).then(response=>{  
                      if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                        let rowsInPage = response.data.data.length;
                        this.setState({
                          data: response.data.data,
                          totalRowsFound:response.data.total_row[0].total_row,
                          rowsInPage:rowsInPage,
                          loading:0,
                          
                      })
                      localStorage.setItem('totalSubstance', response.data.total_row[0].total_row);
                      this.props.context.updateData(response.data.data, response.data.total_row[0].total_row, rowsInPage, 'substance');
                      
                      this.props.context.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'substance_raw');
                        searchTerm.ungrouperd = 1;
                          seacrObject = JSON.stringify(searchTerm); 
                          // console.log(`${API_URL}getConsolidate?seacrhObj=${seacrObject}`)
                          axios.post(`${API_URL}getSum`, {
                            seacrhObj: seacrObject
                          }).then(response=>{
                            
                            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                                              
                              
                              this.setState({
                                ungrouperdData:response.data.sumData[0],
                                ungrouperdDataLoaded: 1
                              })
                            }else if(response.status === 200 && response.data.message === 'Parallel login'){
                              this.setState({
                                unauthorizedModal: !this.state.unauthorizedModal,          
                              })
                            }
                            this.props.context.updateData(response.data.sumData[0], "", "", 'ungrouperdData');
                          })
                          searchTerm.ungrouperd = 0;
                          seacrObject = JSON.stringify(searchTerm); 
                          // console.log(`${API_URL}getConsolidate?seacrhObj=${seacrObject}`)
                          axios.post(`${API_URL}getConsolidate`, {
                            seacrhObj: seacrObject
                            },{
                              headers:{
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer '+localStorage.getItem('token')
                              }           
                            }).then(response=>{  
                            if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                              this.setState({
                                sumData: response.data.consolidatedData,
                                sumDataLoaded:1                                
                              })
                              this.props.context.updateData(response.data.consolidatedData, "", "", 'sumData');
                            }else if(response.status === 200 && response.data.message === 'Parallel login'){
                              this.setState({
                                unauthorizedModal: !this.state.unauthorizedModal,          
                              })
                            }
                          })
                      }else if(response.status === 200 && response.data.message === 'Empty Search'){
                        this.setState({
                          showEmptyModal:true,
                          loading:0
                        })
                      }else if(response.status === 200 && response.data.message === 'Parallel login'){
                        this.setState({
                          unauthorizedModal: !this.state.unauthorizedModal,          
                        })
                      }
                    })
                }else{
                  
                  this.setState({
                    data: value.data.dataSubstance,
                    totalRowsFound:value.data.substanceTotal,
                    rowsInPage:value.data.substanceInPage,
                    loading:0,
                    sumData: value.data.dataSum,
                    sumDataLoaded:value.data.dataSumLoaded,
                    ungrouperdData:value.data.ungrouperdData,
                    ungrouperdDataLoaded: value.data.ungrouperdDataLoaded,
                    searchTerm:searchTerm

                  })
                }
              }
            }else{
              if(this.state.clickedSearch === 1 ){
                this.setState({
                  showEmptyModal:true,
                  loading:0
                })
              }
            }
          }
        }
      }
        
    }
    componentDidUpdate(prevProps, prevState){
      let searchTerm = this.props.searchTerm;
      let oldSearchTerm = prevProps.searchTerm;
      let epmtySerach = (Object.keys(searchTerm).length === 0 && searchTerm.constructor === Object)? true:false;
      if(!epmtySerach){
        sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
        if(!sameSearch){
          let searchT = this.props.searchTerm; 
          this.setState({
              searchTerm:searchT,
              clickedSearch:1,
              loading:1
          })
          axios.post(`${API_URL}auth/checkLogin`, {
            token: localStorage.getItem('token')
          },{
            headers:{
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.getItem('token')
            }           
          }).then(response=>{
            if(response.status === 200 && response.data.login_status === 'other_device'){
              this.setState({
                unauthorizedModal: !this.state.unauthorizedModal,          
              })
            }else if(response.status === 200 && response.data.login_status === 'logged_in'){
              searchT.page = this.state.page;
              let seacrObject = JSON.stringify(searchT);
              //console.log(`${API_URL}getDrugstats?seacrhObj=${seacrObject}`)
              axios.post(`${API_URL}getDrugstats`, {
                  seacrhObj: seacrObject
              },{
                headers:{
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+localStorage.getItem('token')
                }           
              }).then(response=>{              
                
                if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                   
                  let rowsInPage = response.data.data.length;
                  this.setState({
                    data: response.data.data,
                    totalRowsFound:response.data.total_row[0].total_row,
                    rowsInPage:rowsInPage,
                    loading:0,
                  })
                  localStorage.setItem('totalSubstance', response.data.total_row[0].total_row);
                  this.props.context.updateData(response.data.data, response.data.total_row[0].total_row, rowsInPage, 'substance');
                  this.props.context.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'substance_raw');
                  searchT.ungrouperd = 1;
                  seacrObject = JSON.stringify(searchT);
                  //console.log(`${API_URL}getConsolidate?seacrhObj=${seacrObject}`)
                  axios.post(`${API_URL}getSum`, {
                    seacrhObj: seacrObject
                  }).then(response=>{
                   
                    if(response.status === 200 && response.data.message === 'Drugstats loaded!'){                                              
                      this.setState({
                        ungrouperdData:response.data.sumData[0],
                        ungrouperdDataLoaded: 1
                      })
                    }else if(response.status === 200 && response.data.message === 'Parallel login'){
                      this.setState({
                        unauthorizedModal: !this.state.unauthorizedModal,          
                      })
                    }
                    this.props.context.updateData(response.data.sumData[0], "", "", 'ungrouperdData');
                  })
                  searchT.ungrouperd = 0;
                  seacrObject = JSON.stringify(searchT);
                  // console.log(`${API_URL}getConsolidate?seacrhObj=${seacrObject}`)
                  axios.post(`${API_URL}getConsolidate`, {
                    seacrhObj: seacrObject
                    },{
                      headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+localStorage.getItem('token')
                      }           
                    }).then(response=>{                    
                    if(response.status === 200 && response.data.message === 'Drugstats loaded!'){  
                      this.setState({
                        sumData: response.data.consolidatedData,
                        sumDataLoaded:1                        
                      })
                      this.props.context.updateData(response.data.consolidatedData, "", "", 'sumData');
                    }else if(response.status === 200 && response.data.message === 'Parallel login'){
                      this.setState({
                        unauthorizedModal: !this.state.unauthorizedModal,          
                      })
                    }
                  })
                }else if(response.status === 200 && response.data.message === 'Empty Search'){
                  this.setState({
                    showEmptyModal:true,
                    loading:0
                  })
                }else if(response.status === 200 && response.data.message === 'Parallel login'){
                  this.setState({
                    unauthorizedModal: !this.state.unauthorizedModal,          
                  })
                }
              }).catch(function (error) {
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                }
              });
            }
          })
        }
      }
    }
  
  render(){
    const {clickedSearch,rowsInPage,totalRowsFound, page,showMoreLoading,sumDataLoaded, sumData,chartData,ungrouperdData,ungrouperdDataLoaded} = this.state
    


    let search = localStorage.getItem('drugstatsSearchTOne')
    let searchTerm = JSON.parse(search)
    let showPerMonth = true;
    let showRegion = true;
    let orderByOne = "";
    let orderOne = "";
    if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
      showPerMonth = searchTerm.selectedPerMonth;
      showRegion = searchTerm.selectedRegionWise;
      orderByOne =   searchTerm.orderByOne;
      orderOne = searchTerm.orderOne;
    }
    //console.log("showperMonth"+showPerMonth, "showregion"+showRegion)
    let hideRegion = false;
    let activeColumns = [];
    if(showPerMonth && showRegion){
      activeColumns = columns;
    }else if(!showPerMonth && showRegion){
      activeColumns = columns;
    }else if(!showPerMonth && !showRegion){
      hideRegion = true;
      activeColumns = columnsNationWide;
    }else if(showPerMonth && !showRegion){
      hideRegion = true;
      activeColumns = columnsNationWide;
    }


    let renderPagination,renderTableBody,renderTableHeader,renderProductTable,renderTableBottom,showMoreLink;
    let showSpinningPill = true;
    let chartModalBody = "";
    let singleChartModalBody = "";
    if(totalRowsFound>0){
      
      let formatTotalRow = totalRowsFound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let formatRownInPage = rowsInPage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); 

        renderPagination=(
          <div className="pagination_style">
            <div className="product_counter">
              {rowsInPage>0? 'Showing '+(formatRownInPage+' of '+ formatTotalRow +''+(totalRowsFound>1?" records":" record") +" found") :"" }
              {
                      (
                        showMoreLoading ?
                        <a href="#" onClick={rowsInPage<totalRowsFound?this.showmore:()=>{}} style={{marginRight:"10px", color:"#e76226"}}className={rowsInPage<totalRowsFound? "show float_right":"hide "}>Show more</a>
                        :<span style={{color:"#e76226",marginRight:"10px"}}className="float_right">loading...</span>
                      )
                }
            </div>
          </div>
          
        )
      } 
      if(totalRowsFound>0){
        renderTableBottom = (
          <tfoot>
            <tr>
              <td colSpan={hideRegion?"5":"6"} style={{"textAlign":"center"}}>{sumDataLoaded ? 
                <button type="button" id="show_more" className="small-button" onClick={(e)=>this.renderChart(e)}>
                  Total for search (Graph)
                </button>
                : 
                <span>Total for search</span> }
              </td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.demographic_size)? "" : new Intl.NumberFormat('sv-SE').format( ungrouperdData.demographic_size ): " "}</td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.patient_count)? "" : new Intl.NumberFormat('sv-SE').format( ungrouperdData.patient_count):" "}</td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.patient_count_t)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.patient_count_t ):" "}</td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.prescription_count)? "" : new Intl.NumberFormat('sv-SE').format( ungrouperdData.prescription_count) :" "}</td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.est_sales_val)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.est_sales_val)+" SEK" :" "}</td>
              
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.prescription_count_t)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.prescription_count_t ):" "}</td>
              {/* <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.ddd_count)? "" : new Intl.NumberFormat('sv-SE').format( ungrouperdData.ddd_count):" "}</td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.ddd_count_t_d)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.ddd_count_t_d ) :" "}</td>
              <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.ddd_count_t)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.ddd_count_t) :" "}</td> */}
            </tr>
          </tfoot>
        )
        
        if(hideRegion){
          renderTableBody = ( 
            <tbody>
              {this.state.data.map((row, index) => {
                return (
                  <tr className="product_row infotag" key={index} id={index}>
                      <td id={index+'_year'} className={(orderByOne === 'year' && orderOne !=='')?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}   >{row.year}</td>
                      <td id={index+'_atc_code'} className={orderByOne === 'atc_code' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{row.atc_code}</td>
                      <td id={index+'_substance'} className={orderByOne === 'substance' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{row.substance}</td>
                      <td id={index+'_age_group'} className={orderByOne === 'age_group' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{row.age_group}</td>
                      <td id={index+'_gender'} className={orderByOne === 'gender' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{(row.gender+"" === "0")?'Kvinnor':'Man'}</td>
                      <td id={index+'_demographic_size'} className={orderByOne === 'demographic_size' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.demographic_size? isNaN(row.demographic_size)? "" : new Intl.NumberFormat('sv-SE').format( row.demographic_size): " "}
                      </td>
                      <td id={index+'_patient_count'} className={orderByOne === 'patient_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>                        
                        {row.patient_count? isNaN(row.patient_count)? "" : new Intl.NumberFormat('sv-SE').format( row.patient_count): " "}
                      </td>
                      
                      <td id={index+'_patient_count_t'} className={orderByOne === 'patient_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)} >                         
                        {row.patient_count_t? isNaN(row.patient_count_t)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( row.patient_count_t ):" "}
                      </td>
                      
                      <td id={index+'_prescription_count'} className={orderByOne === 'prescription_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.prescription_count? isNaN(row.prescription_count)? "" : new Intl.NumberFormat('sv-SE').format( row.prescription_count): " "}
                      </td>
                      <td id={index+'_est_sales_val'} className={orderByOne === 'est_sales_val' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id)}>
                        {row.est_sales_val? isNaN(row.est_sales_val)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( row.est_sales_val)+" SEK": " "}
                      </td>
                      <td id={index+'_prescription_count_t'} className={orderByOne === 'prescription_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.prescription_count_t? isNaN(row.prescription_count_t)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.prescription_count_t): " "}                        
                      </td>
                      {/* <td id={index+'_ddd_count'} className={orderByOne === 'ddd_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.ddd_count? isNaN(row.ddd_count)? "" : new Intl.NumberFormat('sv-SE').format( row.ddd_count): " "}
                      </td>
                      <td id={index+'_ddd_count_t_d'} className={orderByOne === 'ddd_count_t_d' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.ddd_count_t_d? isNaN(row.ddd_count_t_d)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.ddd_count_t_d): " "}
                      </td>
                      <td id={index+'_ddd_count_t'} className={orderByOne === 'ddd_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.ddd_count_t? isNaN(row.ddd_count_t)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.ddd_count_t): " "}
                      </td> */}
                  </tr>
                );
              })}
            </tbody> 
          );

        }else{
          renderTableBody = ( 
            <tbody>
              {this.state.data.map((row, index) => {
                
                return (
                  <tr className="product_row infotag" key={index} id={index}>
                      
                      <td id={index+'_year'} className={(orderByOne === 'year' && orderOne !=='')?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}   >{row.year}</td>
                      <td id={index+'_county_name'} className={orderByOne === 'county_name' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)} >{row.county_name}</td>
                      <td id={index+'_atc_code'} className={orderByOne === 'atc_code' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{row.atc_code}</td>                        
                      <td id={index+'_substance'} className={orderByOne === 'substance' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{row.substance}</td>
                      <td id={index+'_age_group'} className={orderByOne === 'age_group' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{row.age_group}</td>
                      <td id={index+'_gender'} className={orderByOne === 'gender' && orderOne !==''?"text-left contract-price":"text-left"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>{(row.gender+"" === "0")?'Kvinnor':'Man'}</td>
                      <td id={index+'_demographic_size'} className={orderByOne === 'demographic_size' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.demographic_size? isNaN(row.demographic_size)? "" : new Intl.NumberFormat('sv-SE').format( row.demographic_size): " "}
                      </td>
                      <td id={index+'_patient_count'} className={orderByOne === 'patient_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>                        
                        {row.patient_count? isNaN(row.patient_count)? "" : new Intl.NumberFormat('sv-SE').format( row.patient_count): " "}
                      </td>
                      
                      <td id={index+'_patient_count_t'} className={orderByOne === 'patient_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)} >                         
                        {row.patient_count_t? isNaN(row.patient_count_t)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( row.patient_count_t ):" "}
                      </td>
                      
                      <td id={index+'_prescription_count'} className={orderByOne === 'prescription_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.prescription_count? isNaN(row.prescription_count)? "" : new Intl.NumberFormat('sv-SE').format( row.prescription_count): " "}
                      </td>
                      <td id={index+'_est_sales_val'} className={orderByOne === 'est_sales_val' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.est_sales_val? isNaN(row.est_sales_val)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( row.est_sales_val)+" SEK": " "}
                      </td>
                      <td id={index+'_prescription_count_t'} className={orderByOne === 'prescription_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.prescription_count_t? isNaN(row.prescription_count_t)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.prescription_count_t): " "}                        
                      </td>
                      {/* <td id={index+'_ddd_count'} className={orderByOne === 'ddd_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.ddd_count? isNaN(row.ddd_count)? "" : new Intl.NumberFormat('sv-SE').format( row.ddd_count): " "}
                      </td>
                      <td id={index+'_ddd_count_t_d'} className={orderByOne === 'ddd_count_t_d' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.ddd_count_t_d? isNaN(row.ddd_count_t_d)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.ddd_count_t_d): " "}
                      </td>
                      <td id={index+'_ddd_count_t'} className={orderByOne === 'ddd_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                        {row.ddd_count_t? isNaN(row.ddd_count_t)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.ddd_count_t): " "}
                      </td> */}
                  </tr>
                );
              })}
            </tbody> 
          );
        }
       if(this.state.individualChartData.length>0){
         
        singleChartModalBody =   (
          <div id="modal_body">
            <div className="">
                <span className="modal_substance_header">
                  <b>{this.state.chartSingleSubstance}</b>
                </span>
                
              
            </div>
            <div className="chart_info">  
              <div>
                 <ul className="chart-values">
                   <li><span>Region:</span><span className="active-pill">{this.state.chartSingleRegion}</span></li>
                   <li><span>Age:</span><span className="active-pill">{this.state.chartSingleAge}</span></li>
                   <li><span>Gender:</span><span className="active-pill">{this.state.chartSingleGender}</span></li>
                 </ul>            
              </div>
              <div>
                <ul className="area-colors">
                   <li>
                     <div className="patient"></div>
                     <span>Patient count</span>
                   </li>
                   <li>
                     <div className="prescription"></div>
                     <span>Prescription count</span>
                   </li>
                 </ul>
              </div>
              </div>
            <div className="o-hidden">
                <Chart
                  chartType="AreaChart"
                  height="360px"
                  loader={<div>Loading Chart</div>}
                  data={this.state.individualChartData}
                  options={options}
                />
              </div>
            </div>
          )} else {
            singleChartModalBody= (
              <div>loading...</div>
            )
          }
       
        if(this.state.sumDataLoaded){
          
        chartModalBody =  (this.state.sumData.length>0)
          ? (
            <div id="modal_body">
              <div className="">
                
                  <span className="modal_substance_header">
                    <b>{this.state.chartSubstance}</b>
                  </span>
                
              </div>
              <div className="chart_info">  
                <div>
                   <ul className="chart-values">
                     <li><span>Region:</span><span className="active-pill">{this.state.chartRegion}</span></li>
                     <li><span>Age:</span><span className="active-pill">{this.state.chartAge}</span></li>
                     <li><span>Gender:</span><span className="active-pill">{this.state.chartGender}</span></li>
                   </ul>            
                </div>
                <div>
                  <ul className="area-colors">
                     <li>
                       <div className="patient"></div>
                       <span>Patient count</span>
                     </li>
                     <li>
                       <div className="prescription"></div>
                       <span>Prescription count</span>
                     </li>
                   </ul>
                </div>
                </div>
              <div className="o-hidden">
                  <Chart
                    chartType="AreaChart"
                    height="360px"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={options}
                  />
                </div>
            </div>
          ):(
            <div>loading...</div>
          )
        }
      }

      

      renderTableHeader = (
        <tr>
          {activeColumns.map((suggestion, index) => {
            let sortingView = (
              <>  <span  style={{marginRight:"5px"}} id={suggestion.field+'_loading'} className={(orderByOne===suggestion.field && this.state.sorting)?"":"hidden" }>{faLoading}</span></>
              );
              let sortedView = (
                <>
                  <span id={suggestion.field+'_asc'} className={(orderOne === 'asc' && orderByOne===suggestion.field)? 'asc':'asc hidden' }>▲</span>
                  <span id={suggestion.field+'_desc'} className={(orderOne === 'desc'&& orderByOne===suggestion.field)? 'desc':'desc hidden' }>▼</span>
                </>
              )
             
            return (
              <th key={index} id={suggestion.field} 
                className = {(suggestion.field === orderByOne && orderOne !=="")?' clickable contract-price': 'clickable' }
                onClick = {this.sortTable}
              >
                    
                    {suggestion.label}
                    &nbsp;
                    {
                        this.state.sorting ? sortingView:sortedView
                      
                    }
                    
              </th>
            );
          })}
        </tr>
      );
     

    if(clickedSearch === 1 ){
          
      showSpinningPill = true;
      if(this.state.loading && !this.state.showEmptyModal){
          renderProductTable = (
              <div className={(showSpinningPill) ? 'spinning_pill': 'spinning_pill_hidden'}>
                  <img src={spinning_pill} alt="spinning_pill" />
              </div>              
          )
          
      }else if (this.state.loading && this.state.showEmptyModal){
        renderProductTable = (
          <div className="no_data_found">Empty search parameters</div>            
        )
      }else{
        if(this.state.data.length > 0 ){
            showSpinningPill = false;
            renderProductTable = (
                <table id="substanceTable">
                  <thead>
                    {renderTableHeader}
                  </thead>      
                  {renderTableBody}
                  {renderTableBottom}                
                </table>
            )
        }else{
          renderProductTable = (
            <div className="no_data_found">No records found</div>            
          )
        }
          
          
      }
    }else if(clickedSearch === 0 ){
      showSpinningPill = false;
          renderProductTable = (
              <div className="no_data_found">Search for drugstats</div>            
          )
    }
    
    return ( 
        <React.Fragment>
             <div>
                <div id="main-table-wrapper" style={{overflowX:"scroll"}}>
                    {renderPagination}
                    {renderProductTable}
                </div>
                
                <div style={{width:'100vw'}}>
                <Modal style=  {{maxWidth: '500px', width: '500px'}}  isOpen={this.state.showEmptyModal} toggle={this.toogleModal}>
                    <ModalHeader  toggle={this.toogleModal} charCode="x" className="modal_title">Empty search parameters for this tab!</ModalHeader>
                    <div className="form_alert">
                        <p> You must choose at least one search parameter from these options: </p>
                        <div className="option_detail">
                        <ul className="modal_option_names">
                        <li>Product</li>
                        <li>Substance</li>
                        <li>ATC code</li>
                        <li>Year</li>
                        <li>Month</li>
                        <li>Gender</li>
                        <li>Age group</li>
                        <li>Region</li>
                        <li>Healthcare facility</li>

                        </ul>
                        </div>
                        
                    
                    </div>
                    <ModalFooter> 
                      <button onClick={this.toogleModal} type="button" className="form-button">Ok</button>                 
                    </ModalFooter>
                  </Modal>
                </div>
                <div >
                <Modal style=  {{maxWidth: '80vw', width: '50%'}}  isOpen={this.state.chartModal} toggle={this.renderChart}>
                    <ModalHeader  toggle={this.renderChart} charCode="x" className="modal_title">Prescription and patient count</ModalHeader>
                    {chartModalBody}
                    
                    <ModalFooter> 
                      <button onClick={this.renderChart} type="button" className="form-button">Ok</button>                 
                    </ModalFooter>
                  </Modal>
                </div>
                <div >
                <Modal style=  {{maxWidth: '80vw', width: '50%'}}  isOpen={this.state.singleDataChartModal} toggle={this.renderSingleDataChart}>
                    <ModalHeader  toggle={this.renderSingleDataChart} charCode="x" className="modal_title">Prescription and patient count</ModalHeader>
                    {singleChartModalBody}
                    
                    <ModalFooter> 
                      <button onClick={this.renderSingleDataChart} type="button" className="form-button">Ok</button>                 
                    </ModalFooter>
                  </Modal>
                </div>
                <div style={{width:'50vw'}}>
                    <Modal size="lg" isOpen={this.state.unauthorizedModal}>
                      <ModalHeader  charCode="x" className="modal_title">Session Missmatched, you have been logged out!</ModalHeader>
                      <ModalBody className="modalBody">
                        The same account is not allowed to be used on different devices simultaneously.                            
                      </ModalBody>
                      <ModalFooter> 
                        <button onClick={this.showTimeOut} type="button" className="form-button">Ok</button>                 
                      </ModalFooter>
                    </Modal>
              </div>
            </div>
        </React.Fragment>
     );
}
}
const withContext = (Component) => {
   
    return(props)=>(
      <DataContext.Consumer>{(context)=>{
          return ( 
              <Component {...props} context={context} />
          );
      }}
      </DataContext.Consumer>
    )
      
  
}
export default withContext(Substance);