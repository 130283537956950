import React, { Component } from 'react';
import { MainContext } from '../../context/MainContext';
import { DataContext } from '../../context/DataContextProvider';
import logo from '../../images/logo.png'
import { faSearch, faCog, faArrowLeft, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPills, faDownload,  faVenusMars, faUserFriends ,faBriefcaseMedical ,faUsers,faTablets, faSearchLocation, faCalendarDay} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Input, Form,FormGroup, InputGroupAddon, InputGroup  } from 'reactstrap';
import Help from '../helpSection/Help'
import { Modal, ModalHeader,ModalBody, ModalFooter} from 'reactstrap';
import Product from '../tabComponents/Product'
import Substance from '../tabComponents/Substance'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import "react-datepicker/dist/react-datepicker.css";
import molecule from '../../images/molecule.png';
import atcIcon from '../../images/atc.png';
import debounce from "debounce-promise";
import {API_URL} from '../../constant/ApiLink'
import {API_EXPORT_URL} from '../../constant/ApiLink'
import {MONTHS} from '../../constant/Months'
//import {AGE_GROUP_SUBSTITUTION} from '../../constant/AgeGroup'
import ReactGA from 'react-ga'

import axios from 'axios'
import _ from "lodash";
import SaveSearchForm from './SaveSearchForm'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
    } from 'reactstrap';

let AGE_GROUP_SUBSTITUTION = [];
let inputBoxSpace = 20;
const question = <FontAwesomeIcon icon={ faQuestionCircle} />
const spinner = <FontAwesomeIcon icon={faCog} spin  />
const medical = <FontAwesomeIcon icon={ faBriefcaseMedical} />
const signout = <FontAwesomeIcon icon={ faSignOutAlt} />
const gender =<FontAwesomeIcon icon = {faVenusMars}/> 
const AgeGroup =<FontAwesomeIcon icon = {faUserFriends}/>
const group =<FontAwesomeIcon icon = {faUsers}/>
const fileContract = <FontAwesomeIcon icon={ faFileContract} />
const pills = <FontAwesomeIcon icon={ faPills} />
const download = <FontAwesomeIcon icon = {faDownload}/>
const tablets = <FontAwesomeIcon icon={faTablets}/>
const area = <FontAwesomeIcon icon={faSearchLocation}/>
const calendarDay = <FontAwesomeIcon icon={faCalendarDay}/>
const trash = <FontAwesomeIcon icon ={faTrashAlt}/>



const downLoadStyle = {
  inactive:{
      color:"#dedede",
      padding:"7px"
  },
  spinning:{
    padding:"7px",
    color:"#737576"
  }
  //...other styles...
};
const customStyleLast = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    borderRadius: state.isFocused ? "5px 5px 0 0" : 5,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#02354a" : "#02354a",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ccc" : "#ccc"
    },
    zIndex:2
    
    
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    zIndex:3,
   
    
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    height: 200,
    minHeight: 200
    
  })

}
const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    borderRadius: state.isFocused ? "5px 5px 0 0" : 5,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#02354a" : "#02354a",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ccc" : "#ccc"
    },
    zIndex:2
    
    
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    zIndex:3
    
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    
  })
};
const onlyUnique = (value, index, self)=>{
  return self.indexOf(value) === index;
}
const search = <FontAwesomeIcon icon={ faSearch} />
const LeftArraow = <FontAwesomeIcon icon={ faArrowLeft} />
let emptySpace = "&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;&emsp;";
let products=[];
  let substance = [];
  let atc =[];
  let form = [];
  let region = [];
  let tutorialReady= 0;
  let maxOptions = 19;
class Dashboard extends Component {
    constructor(props){
      super(props);
      this.state={
        loggedInStatus: (typeof this.props.location.state !== 'undefined') ?this.props.location.state.loggedInStatus:'NOT_LOGGED_IN',
        user: (typeof this.props.location.state !== 'undefined') ?this.props.location.state.user:{},
        token:(typeof this.props.location.state !== 'undefined') ?this.props.location.state.token:"",
        active: true,
        tutorialActive :0,
        seenOnce:0,
        infoDivactive: true,
        rightPanClicked:false,
        selectedProduct: "",
        selectedSubstance : "",
        selectedYearsList:"",
        selectedYearsList2:"",
        selectedGender:"",
        selectedAgeGroup:"",
        selectedTherapyGroup:"",
        selectedAtc:"",
        selectedSubsMonths:"",
        selectedSubsGender:"",
        selectedSubsAgeGroup:"",
        selectedRegion:"",
        selectedForm:"",
        selectedSubsRegion:"",
        selectedHealthCare:"",
        searchTermOne:{},
        searchTermTwo:{},
        ageGroups:[],
        yearsList:[{value:'all', label:'All years'}],
        substYearList:[{value:'all', label:'All years'}],
        therapyGroup:[],
        specializations:[],
        allRegions:[],
        slectedTab:0,
        clickedSearch:0,
        disabledOption:false,
        formTab:0,
        fullEmpty:false,
        emptyOne:false,
        emptyTwo:false,
        savedSearches :[],
        savedSearchRaw:[],
        perMonth:true,
        regionWise:true,        
        helpModal:false,
        modal:false,
        searchIsSaved:false,
        unauthorizedModal:false,
        saveSearchModal:false,
        expired:false,
        activeSaveSearch:false,
        search_title:"",
        epmtyTitle:false,
        downLoadActive:false,
        showSpinner:false,
        dropdownOpen:false,
        veryLargeData:false,
        atcPlaceHolder:false,
        regionPlaceHolder:false,
        atcPlaceHolderText:"ATC",
        subsPlaceHolder:false,
        prodPlaceHolder:false,
        healthCareHolder:false,
        therapyGroupHolder:false,
        ageGroupHolder:false,
        genderHolder:false,
        formPlaceHolderText:"Only for Pres/Prod",
        formPlaceHolderClass:"full_width only_prod",
        formOptions:[],
        limitedFormsLoaded:false       

      }
      const wait = 100; // milliseconds
      const loadOptions = (inputValue, type) => this.getAsyncOptions(inputValue, type);
      this.debouncedLoadOptions = debounce(loadOptions, wait, {
          loading: true
      });
      this.toggleClass.bind(this);
      this.toggleInfoDivClass.bind(this)
      this.handelLogout.bind(this)
      this.handleSubstanceChange.bind(this)
      this.handleSubstanceChange2.bind(this)
      this.handleAtcChange.bind(this)
      
      this.handleFormChange.bind(this)
      this.loadYears.bind(this)
      this.loadTherapyGroup.bind(this)
      this.handleRegionChange.bind(this)
      this.handleProductChange.bind(this)
      this.loadSpecializations.bind(this)
      this.loadSavedSearch.bind(this)
      this.handleYearsList.bind(this)
      this.handleGender.bind(this)
      this.handleAgeGroup.bind(this)
      this.handleTherapyGroup.bind(this)
      this.handleSubsMonths.bind(this)
      this.handleSubsGender.bind(this)
      this.handleSubsAgeGroup.bind(this)
      this.handleSubsRegion.bind(this)
      this.handleHealthCare.bind(this)
      this.resetForm.bind(this)
      this.searchItems.bind(this)
      this.toogleModal.bind(this)
      this.toogleSecondModal.bind(this)
      this.showTimeOut.bind(this)
      this.tabSelected.bind(this)
      this.doNothing.bind(this)
      this.loadThisSearch.bind(this)
      this.removeThisSearch.bind(this)
      this.loadSelectedObject.bind(this)
      this.startDownloadOne.bind(this)
      this.startDownloadTwo.bind(this)
      this.loadRegions.bind(this)
      this.showDropdown.bind(this)
      this.facilityPercentage.bind(this)
      this.perMonthOnly.bind(this)
      this.regionWiseData.bind(this)
      this.showHelpModal.bind(this)
      this.changePlaceHolderText.bind(this)
      this.loadSearchOne.bind(this)
      this.loadSearchTwo.bind(this)
      this.saveAndReloadStrings.bind(this)
      this.loadProduct.bind(this)
      this.loadSubstance.bind(this)
      this.loadFormOptions.bind(this)      
    }

    
   
    saveAndReloadStrings=(e, sName="")=>{ 
        let title = sName;
        //this.loadSavedSearch()
        let searchTermOne = localStorage.getItem('drugstatsSearchTOne');
        let searchTermTwo = localStorage.getItem('drugstatsSearchTTwo');
        axios.post(`${API_URL}saveSearches`, {
        searchTermOne:searchTermOne,
        searchTermTwo:searchTermTwo,
        searchTitle:title
        }).then(response=>{  
            this.setState({
            savedSearches:response.data.data
            })
        }).catch( (err) => {
            console.log(err)
        })
      
    }

    changePlaceHolderText=(option)=>{
      if(option==="ATC"){
        this.setState({
          atcPlaceHolder:true,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"          
        })
      }else if(option === "Substance"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:true,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"          
        })
      }else if(option === "Product"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:true,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(["Year", "Month"].includes(option)){ 
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(option === "Region"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:true,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(option === "HealthCare"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:true,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(option === "TherapyGroup"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:true,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(option === "AgeGroup"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:true,
          genderHolder:false,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(option === "Gender"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:true,
          formlaceHolder:false,
          formPlaceHolderText:"Only for Pres/Prod"
        })
      }else if(option === "Form"){
        this.setState({
          atcPlaceHolder:false,
          subsPlaceHolder:false,
          prodPlaceHolder:false,
          regionPlaceHolder:false,
          healthCareHolder:false,
          therapyGroupHolder:false,
          ageGroupHolder:false,
          genderHolder:false,
          formlaceHolder:true,
          formPlaceHolderText:"Type to select"          
        })
      }
      


    }

    showHelpModal=()=>{
      this.setState({
        helpModal:!this.state.helpModal
      })
    }

    perMonthOnly=()=>{
      this.setState({
        perMonth:!this.state.perMonth
      })
    }

    regionWiseData=()=>{
      this.setState({
        regionWise:!this.state.regionWise
      })
    }

    showDropdown=(e)=>{
      if(e.currentTarget.parentElement !== null){
        let selectors = ["dropdown nav-item","dropdown show nav-item"];
        let navItems = selectors.includes(e.currentTarget.parentElement.classList.value);    
        if(navItems){
          this.setState({
            dropdownOpen:!this.state.showDropdown
          })
        }
      }else{
        this.setState({
          dropdownOpen:false
        })
      }
    }
    facilityPercentage=()=>{
      if(localStorage.getItem("totalProd")<200000){
        if (localStorage.getItem("drugstatsSearchTTwo") !== null) {
          this.setState({
            showSpinner:true
          })
          let searchTermTwo = localStorage.getItem('drugstatsSearchTTwo');
          let searchTerm2 = JSON.parse(searchTermTwo);
          if(searchTerm2 !== {}){
            searchTerm2.facilityPercentage = 1;
            searchTermTwo = JSON.stringify(searchTerm2);
            axios.post(`${API_URL}getDrugStatsSubstitutions`, {
              seacrhObj: searchTermTwo
            }).then(response=>{ 
              if(response.data.data.success){
                ReactGA.event({
                  category: 'XLSX_Export',
                  action: 'export',
                  label: 'Export Facility Percentage'
                })
                let a = document.createElement('a');
                a.href = `${API_URL}/export/${response.data.data.link}`;
                //a.href = `${API_URL}storage/${response.data.data.link}`;
                a.click();
              }
              this.setState({
                showSpinner:false,
                dropdownOpen:false
              })
      
            }).catch( (err) => {
              this.setState({
                showSpinner:false,
                dropdownOpen:false
              })
            })
          }
        }

      }else{
        this.setState({
          veryLargeData:true,
          modal:true,
          showSpinner:false,
          dropdownOpen:false
        })
      }
      
      
    }
    startDownloadTwo=()=>{
      if(localStorage.getItem("totalProd")<200000){
        if (localStorage.getItem("drugstatsSearchTTwo") !== null) {
          this.setState({
            showSpinner:true
          })
          let searchTermTwo = localStorage.getItem('drugstatsSearchTTwo');
          let searchTerm2 = JSON.parse(searchTermTwo);
          searchTerm2.download = 1;
          searchTermTwo = JSON.stringify(searchTerm2);
          axios.post(`${API_URL}getDrugStatsSubstitutions`, {
            seacrhObj: searchTermTwo
          }).then(response=>{ 
            if(response.data.data.success){
              ReactGA.event({
                category: 'XLSX_Export',
                action: 'export',
                label: 'Export Pres. Product'
              })
              let a = document.createElement('a');
              a.href = `${API_URL}/export/${response.data.data.link}`;
              //a.href = `${API_URL}storage/${response.data.data.link}`;
              a.click();
            }
            this.setState({
              showSpinner:false,
              dropdownOpen:false
            })
    
          }).catch( (err) => {
            this.setState({
              showSpinner:false,
              dropdownOpen:false
            })
          })
        }
      }else{
        this.setState({
          veryLargeData:true,
          modal:true,
          showSpinner:false,
          dropdownOpen:false
        })
      }
      
    
     
    }
  startDownloadOne=()=>{
    if(localStorage.getItem("totalSubstance")<200000){
      if (localStorage.getItem("drugstatsSearchTOne") !== null) {
        this.setState({
          showSpinner:true
        })
        let searchTermOne = localStorage.getItem('drugstatsSearchTOne');
        
        let searchTerm1 = JSON.parse(searchTermOne);
        
        searchTerm1.download = 1;
        searchTermOne = JSON.stringify(searchTerm1);
        axios.post(`${API_URL}getDrugstats`, {
            seacrhObj: searchTermOne
        },{
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token')
          }           
        }).then(response=>{ 
          if(response.data.data.success){
            ReactGA.event({
              category: 'XLSX_Export',
              action: 'export',
              label: 'Export Pres. Substance'
            })
            let a = document.createElement('a');
            a.href = `${API_EXPORT_URL}/export/${response.data.data.link}`;
            a.click();
            
          }
          this.setState({
            showSpinner:false,
            dropdownOpen:false
          })
        }).catch( (err) => {
          this.setState({
            showSpinner:false,
            dropdownOpen:false
          })
        })
      }
    }else{
      this.setState({
        veryLargeData:true,
        modal:true,
        showSpinner:false,
        dropdownOpen:false
      })
    }
  }

    loadSelectedObject=(label, value, selector)=>{
      if(label.includes("; ")){
          let selectArray = [];
          let searchStringArray = label.split("; ");
          let searchValArry = value.split("; ")
          let arrWithVal = []  
          let arrWithLabel = [] 
          for (const [key, value] of Object.entries(searchStringArray)) {
            if(selector === 'selectedYearsList'){
              arrWithVal.push({value: parseInt(value) , label: parseInt(value)})
            }else if(selector === 'selectedSubstance'){
                arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
            }else if(selector === 'selectedSubsMonths'){
                //console.log( label, searchValArry[key])
              arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
            }else if(selector === 'selectedRegion'){
              arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
            }else if(selector === 'selectedGender'){
              arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})               
            }else if(selector === 'selectedAgeGroup'){                
              arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})               
            }else{
              arrWithLabel.push({value: value , label:value})
            }
          }
            
          if(selector === 'selectedYearsList'){
            this.handleYearsList(arrWithVal)
          }else if(selector === 'selectedSubstance'){
            this.handleSubstanceChange(arrWithVal, false)  
          }else if(selector === 'selectedSubsMonths'){
            this.handleSubsMonths(arrWithVal)
          }else if(selector === 'selectedProduct'){
            this.handleProductChange(arrWithLabel, false)
          }else if(selector === 'selectedAtc'){
            this.handleAtcChange(arrWithLabel)
          }else if(selector === 'selectedRegion'){
            this.handleRegionChange(arrWithVal)
          }else if(selector === 'selectedGender'){
            this.handleGender(arrWithVal)
          }else if(selector === 'selectedAgeGroup'){
            this.handleAgeGroup(arrWithVal)  
          }
      }else{
        let arrWithVal = [{value: value, label: label}]  
        let arrWithLabel = [{value: label, label: label}]        
        if(selector === 'selectedSubstance'){
          this.handleSubstanceChange([{value: parseInt(value) , label: label}], false)          
        }else if(selector === 'selectedProduct'){
          this.handleProductChange(arrWithVal,false)
        }else if(selector === 'selectedAtc'){
          this.handleAtcChange(arrWithLabel)
        }else if(selector === 'selectedYearsList'){          
          let val = (value==="")? label: value;
          this.handleYearsList([{value: parseInt(val) , label: parseInt(val)}])
        }else if(selector === 'selectedSubsMonths'){
          this.handleSubsMonths(arrWithVal)
        }else if(selector === 'selectedRegion'){
          this.handleRegionChange([{value: parseInt(value) , label: label}])
        }else if(selector === 'selectedGender'){
          this.handleGender(arrWithVal)
        }else if(selector === 'selectedTherapyGroup'){
          this.handleTherapyGroup(arrWithVal[0])
        }else if(selector === 'selectedHealthCare'){
          this.handleHealthCare({value: parseInt(value) , label: label})
        }else if(selector === 'selectedAgeGroup'){
          this.handleAgeGroup([{value: parseInt(value) , label: label}])  
        }

      }
      
    }
    removeThisSearch=(e,id)=>{
      e.preventDefault();
      let searchId = id;
      axios.post(`${API_URL}removedSavedSearch`,
      {
        id:searchId           
      },{
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token')
        }           
      }).then(response=>{ 
        if(response.status){
          this.loadSavedSearch();
        }
        
      })     
      
    }
    loadSearchTwo=(stringTwo)=>{
      let objectTwo = JSON.parse(stringTwo);
      let keysTwo = Object.keys(objectTwo);
      keysTwo.forEach(element=>{
        let valueArray= ["selectedProduct","selectedSubsMonths","selectedForm","selectedHealthCare"]
        if(valueArray.includes(element)){            
          if(element === "selectedHealthCare"){
            this.handleHealthCare(objectTwo[element])
          }else{
            if(objectTwo[element].length>0){                  
              let arrWithVal = [] 
              for (let index = 0; index < objectTwo[element].length; index++) {
                  arrWithVal.push(objectTwo[element][index])                 
              }
              if(element === "selectedProduct"){
                this.handleProductChange(arrWithVal)
              }else if(element === "selectedSubsMonths"){
                this.handleSubsMonths(arrWithVal)
              }else if(element === "selectedForm"){
                this.handleFormChange(arrWithVal)
              }
            
            }
          }
        }
      })

    }
    loadSearchOne=(stringOne)=>{
      let objectOne = JSON.parse(stringOne);
      let keysOne = Object.keys(objectOne);      
      keysOne.forEach(element => {
        var valueArray = ["selectedSubstance","selectedAtc","selectedRegion", "selectedAgeGroup", "selectedGender", "selectedYearsList","selectedTherapyGroup","selectedPerMonth", "selectedRegionWise"];
        if(valueArray.includes(element)){            
          if(element === "selectedTherapyGroup"){
            this.handleTherapyGroup(objectOne[element])
          }else if(element === "selectedPerMonth"||element ==="selectedRegionWise"){
            if(element === "selectedPerMonth"){
              let val = objectOne[element];
              document.getElementById("per_month_only_chk").checked = (objectOne[element] === true)?true:false;
              this.setState({
                perMonth: (objectOne[element] === true)?true:false 
              })               
            }else if(element === "selectedRegionWise"){
              document.getElementById("region_wise_chk").checked = (objectOne[element] === true)?true:false;
              this.setState({
                regionWise: (objectOne[element] === true)?true:false 
              })
            }
          }else{
            if(objectOne[element].length>0){                  
              let arrWithVal = [] 
              for (let index = 0; index < objectOne[element].length; index++) {
                  arrWithVal.push(objectOne[element][index])                 
              }
              if(element === "selectedSubstance"){
                this.handleSubstanceChange(arrWithVal)
              }else if(element === "selectedAtc"){
                this.handleAtcChange(arrWithVal)
              }else if(element === "selectedRegion"){
                this.handleRegionChange(arrWithVal)
              }else if(element === "selectedAgeGroup"){
                this.handleAgeGroup(arrWithVal)
              }else if(element === "selectedGender"){
                this.handleGender(arrWithVal)
              }else if(element === "selectedYearsList"){
                this.handleYearsList(arrWithVal)
              }
            
            }
          }
        }
      })
    }

    
    loadThisSearch=(e, id, search_id)=>{
      e.preventDefault();
      this.resetForm(1);
      if(!this.state.active){
        this.setState({
          active:true
        })
      }
      
      let allStrings = this.props.DataContext.data.savedSearchData
      if(id in allStrings){
        let stringOne = this.props.DataContext.data.savedSearchData[id].search;
        let stringTwo = this.props.DataContext.data.savedSearchData[id].search2;
        if(stringOne !== "" && stringOne !== "{}"){
          this.loadSearchOne(stringOne);
        }
        if(stringTwo !== "" && stringTwo !== "{}"){
          this.loadSearchTwo(stringTwo);
        }
      }else{
        
        let stringOne = "";
        let stringTwo = "";
        axios.post(`${API_URL}getSearchStringById`,
        {
          id:search_id           
        },{
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token')
          }           
        }).then(response=>{ 
          if(response.status){
            stringOne = response.data.data[0].search
            stringTwo = response.data.data[0].search2
            this.loadSearchOne(stringOne);
            this.loadSearchTwo(stringTwo);
          }
        })
        

      }
      
    }
    
    toogleLeftPannel=(e)=>{
      var element = document.getElementById(e.target.id);
       if(( (e.target.id === 'search_pannel')&& element.classList.contains('grid_item1_toggled'))){
        this.toggleClass();
       }
     }
    loadSavedSearch=()=>{
      axios.post(`${API_URL}getSavedSearches`,
      {
        token:localStorage.getItem('token')           
      }).then(response=>{
        this.setState({
          savedSearches:response.data.data,
          savedSearchRaw:response.data.raw_data
        })
        this.props.DataContext.updateData(response.data.raw_data,1, 0, 'savedSearch',"","",0);
      })
    }
    doNothing=(e)=>{
      e.preventDefault();
      return;
    }
    tabSelected=(e, tab)=>{
      if(tab==='product'){
        this.setState({
          slectedTab:1
        })
      }else{
        this.setState({
          slectedTab:0
        })
      }
    }
  
    
    toogleSecondModal=()=>{
      this.setState({
        unauthorizedModal:!this.state.unauthorizedModal
      })
    }
    toogleModal=()=>{
      this.setState({
        modal:!this.state.modal
      })
    }
    searchItems=(event)=>{
      let selectedHealthCare = (this.state.selectedHealthCare === null ? "" :(this.state.selectedHealthCare !== ""?this.state.selectedHealthCare:""));
      let selectedSubsRegion = (this.state.selectedRegion === null ? "" :(this.state.selectedRegion !== ""?this.state.selectedRegion:""));
      let selectedSubsAgeGroup = (this.state.selectedAgeGroup === null ? "" :(this.state.selectedAgeGroup !== ""?this.state.selectedAgeGroup:""));
      let selectedSubsGender = (this.state.selectedGender === null ? "" :(this.state.selectedGender !== ""?this.state.selectedGender:"")); 
      let selectedSubsMonths = (this.state.selectedSubsMonths === null ? "" :(this.state.selectedSubsMonths !== ""?this.state.selectedSubsMonths:""));
      let selectedYearsList2 = (this.state.selectedYearsList === null ? "" :(this.state.selectedYearsList !== ""?this.state.selectedYearsList:""));
      
      
      let selectedAgeGroup = (this.state.selectedAgeGroup === null ? "" :(this.state.selectedAgeGroup !== ""?this.state.selectedAgeGroup:""));
      let selectedGender = (this.state.selectedGender === null ? "" :(this.state.selectedGender !== ""?this.state.selectedGender:""));
      let selectedYearsList = (this.state.selectedYearsList === null ? "" :(this.state.selectedYearsList !== ""?this.state.selectedYearsList:""));
      let selectedProduct = (this.state.selectedProduct === null ? "" :(this.state.selectedProduct !== ""?this.state.selectedProduct:""));
      let selectedRegion = (this.state.selectedRegion === null ? "" :(this.state.selectedRegion !== ""?this.state.selectedRegion:""));
      let selectedAtc = (this.state.selectedAtc === null ? "" :(this.state.selectedAtc !== ""?this.state.selectedAtc:""));
      let selectedForm = (this.state.selectedForm === null ? "" :(this.state.selectedForm !== ""?this.state.selectedForm:""));
      
      let selectedSubstance =  (this.state.selectedSubstance === null ? "" :(this.state.selectedSubstance !== ""?this.state.selectedSubstance:""));
      let selectedTherapyGroup = (this.state.selectedTherapyGroup === null ? "" :(this.state.selectedTherapyGroup !== ""?this.state.selectedTherapyGroup:""));
     
      if(
        selectedProduct === "" && selectedSubstance === ""  && selectedAtc === "" && selectedAgeGroup === "" && selectedGender === ""
        && selectedRegion === "" && selectedYearsList === "" &&   selectedTherapyGroup === "" 
        && selectedHealthCare === "" && selectedSubsRegion === "" && selectedSubsAgeGroup === "" && selectedSubsGender === "" && selectedSubsMonths === ""
        && selectedYearsList2 === "" && selectedForm === ""
      ){
        this.setState({
          fullEmpty:true,
          emptyOne:false,
          emptyTwo:false,
        })
        this.toogleModal();
      }else{
        let emptyTermOne = false;
        let emptyTermTwo = false;
        let searchTermOne = {};
        let searchTermTwo = {};
        if(
         selectedSubstance === ""  && selectedAtc === "" && selectedAgeGroup === "" && selectedGender === ""
        && selectedRegion === "" && selectedYearsList === "" && selectedTherapyGroup === ""
        ){
          emptyTermOne = true;
          if(this.state.slectedTab ===0){
            this.setState({
              fullEmpty:false,
              emptyOne:true,
              emptyTwo:false,
            })
            this.toogleModal();
          }
        }
        if(
          selectedProduct === "" && selectedSubstance === "" && selectedAtc === "" 
          && selectedHealthCare === "" && selectedSubsRegion === "" && selectedTherapyGroup === "" && selectedSubsAgeGroup === "" && selectedSubsGender === "" && selectedSubsMonths === ""
          && selectedYearsList2 === "" && selectedForm === ""
        ){
          emptyTermTwo = true;
          if(this.state.slectedTab ===1){
            this.setState({
              fullEmpty:false,
              emptyOne:false,
              emptyTwo:true,
            })
            this.toogleModal();
          }
        }
        if(!emptyTermOne){
          searchTermOne = {
            selectedSubstance : selectedSubstance,
            selectedAtc: selectedAtc,
            selectedRegion: selectedRegion,
            selectedAgeGroup: selectedAgeGroup,
            selectedGender: selectedGender,
            selectedYearsList: selectedYearsList,
            selectedTherapyGroup:selectedTherapyGroup,
            selectedPerMonth:this.state.perMonth,
            selectedRegionWise:this.state.regionWise,
            token:localStorage.getItem('token')          
          }
        }
        if(!emptyTermTwo){
          searchTermTwo={
            selectedProduct: selectedProduct,
            selectedSubstance2: selectedSubstance,
            selectedAtc2: selectedAtc,
            selectedYearsList2 : selectedYearsList,
            selectedSubsMonths:selectedSubsMonths,
            selectedSubsGender : selectedGender,            
            selectedSubsRegion:selectedRegion,
            selectedHealthCare: selectedHealthCare,
            selectedSubsAgeGroup:selectedAgeGroup,
            selectedTherapyGroup:selectedTherapyGroup,
            selectedPerMonth:this.state.perMonth,
            selectedRegionWise:this.state.regionWise,
            selectedForm:selectedForm,
            token:localStorage.getItem('token')
          }
        }
        this.setState({
          searchTermOne: searchTermOne,
          searchTermTwo: searchTermTwo,
          clickedSearch:1,
          activeSaveSearch : true,
          downLoadActive:true
        })
        this.props.MainContext.updateContext(this.state.user, this.state.token, 'LOGGED_IN', searchTermOne, searchTermTwo);
        this.props.DataContext.updateData({}, 0, 0, 'dashboard',"","",1);
        let seacrObjectOne = JSON.stringify(searchTermOne);
        let seacrObjectTwo = JSON.stringify(searchTermTwo);
        localStorage.setItem('drugstatsSearchTOne', seacrObjectOne);
        localStorage.setItem('drugstatsSearchTTwo', seacrObjectTwo);
        
        this.toggleClass()
      }

      ReactGA.event({
        category:'Search',
        action:'Search Records'
      })
        

    }
    resetForm=(fromSavedSearch = 0)=>{
      
      this.setState({
        selectedHealthCare: "",
        selectedSubsRegion : "",
        selectedSubsAgeGroup : "",
        selectedSubsGender:"",
        selectedSubsMonths:"",
        selectedYearsList2: "",
        selectedSubstance2: "",
        selectedAgeGroup:"",
        selectedGender:"",
        selectedYearsList:"",
        selectedProduct:"",
        selectedRegion:"",
        selectedAtc:"",
        selectedAtc2:"", 
        selectedSubstance:"",
        selectedTherapyGroup:"",
        selectedForm:"",       
        disabledOption:false
      });
      if(!fromSavedSearch){
        let  searchTermOne = {
          selectedSubstance : "",
          selectedAtc: "",
          selectedRegion: "",
          selectedAgeGroup: "",
          selectedGender: "",
          selectedYearsList: "",
          selectedTherapyGroup:""  
        }
  
        let  searchTermTwo={
          selectedProduct: "",
          selectedSubstance2: "",
          selectedAtc2: "",
          selectedYearsList2 : "",
          selectedSubsMonths:"",
          selectedSubsGender : "",            
          selectedSubsRegion:"",
          selectedHealthCare: "",
          selectedSubsAgeGroup:"",
          selectedTherapyGroup:"",
          selectedForm:""
        }

        this.setState({
          clickedSearch:0
        })
        
        let seacrObjectOne = JSON.stringify(searchTermOne);
        let seacrObjectTwo = JSON.stringify(searchTermTwo);
        localStorage.setItem('drugstatsSearchTOne', seacrObjectOne);
        localStorage.setItem('drugstatsSearchTTwo', seacrObjectTwo);

      }else{
        this.setState({
          clickedSearch:this.state.clickedSearch? 1:0
        })

        //clickedSearch:0,
      }

      
      
    }

    showTimeOut=()=>{
      localStorage.removeItem('drugstatsSearchTOne');
      localStorage.removeItem('drugstatsSearchTTwo');
      localStorage.removeItem('username');
      localStorage.removeItem('userCompany');
      localStorage.removeItem('token');
      document.location.href="/";    
    }
   
    handleAgeGroup = selectedAgeGroup=>{
        if(selectedAgeGroup === null||selectedAgeGroup === 'null'){          
          this.setState({selectedAgeGroup})
        }else{
          if(selectedAgeGroup.length>0){
            let selected = []
            selectedAgeGroup.forEach(element => {
              if(element.value === "0-subs"){
                  selected.push({value: "0-subs", label: "0-18"})
                }else if(element.value === "1-subs"){      
                  selected.push({value: "1-subs", label: "19+"})
                  
                }else{
                  selected.push({value: element.value, label: element.label})                  
                }
            });
            this.setState({selectedAgeGroup:selected})
    
          }else if(selectedAgeGroup.length === 0){
            this.setState({selectedAgeGroup})
          }
        }
             
      
      
    }
    handleGender = selectedGender=>{
      this.setState({selectedGender})
    }
    handleYearsList=selectedYearsList=>{
      this.setState({selectedYearsList})
    }
    
    loadProduct=(selectedProduct, action)=>{
      
      if(action.action === "select-option"){
          
        if(this.state.selectedSubstance === ""){
          let subsArray = [];
          let atcArray = [];
          selectedProduct.forEach(element => {
            let subsObj = {value: element.substance_id, label: element.substance}
            let atcObj1 = {value: element.name, label:element.name}            
            subsArray.push(subsObj)
            atcArray.push(atcObj1)
          });
          // console.log(this.state.selectedSubstance)

          this.handleSubstanceChange(subsArray,false)
          this.handleAtcChange(atcArray)          
          
        }else{
          
          let omitAtcs = false
          let omitSubstance = false
          if(this.state.selectedAtc === null){
            omitAtcs = true;            
          }
          // console.log(this.state.selectedSubstance)
          if(this.state.selectedSubstance === null){
            omitSubstance = true;            
          }
          let subsArray = [];
          let atcArray = [];
          
          let subsAlreadyIn = false;
          let atcAlreadyIn = false;
          if(!omitSubstance){
            this.state.selectedSubstance.forEach(element => {
              let subsObj = {value: element.value, label: element.label}
              subsArray.push(subsObj)
              
              if(action.option.substance_id === element.value){
                subsAlreadyIn = true;
              }
            });
          }
          if(subsAlreadyIn === false){
            let subsObj1 = {value: action.option.substance_id, label: action.option.substance}
            subsArray.push(subsObj1)
          }
          this.handleSubstanceChange(subsArray,false)

          if(!omitAtcs){
            this.state.selectedAtc.forEach(element => {
              let atcObj = {value: element.value, label: element.label}
              atcArray.push(atcObj)
              if(action.option.name === element.value){
                atcAlreadyIn = true;
              }
            });
          }
          if(atcAlreadyIn === false){
            let atcObj1 = {value: action.option.name, label: action.option.name}
            atcArray.push(atcObj1)
          }
          this.handleAtcChange(atcArray)
          
        }
        
      }
      this.loadFormOptions(selectedProduct,this.state.selectedSubstance,this.state.selectedAtc, "prod");
      this.setState({
        selectedProduct:selectedProduct,
        prodTypeToSelectText: "full_width"
      })

    }
    handleProductChange=(selectedProduct, action=false)=>{
      if(this.state.selectedProduct !== null){
        if(this.state.selectedProduct === "" || this.state.selectedProduct.length <= maxOptions){
          this.loadProduct(selectedProduct, action)
        }else if(action.action ==="remove-value" || action.action ==="pop-value"){
          this.setState({selectedProduct})
        }
        if(selectedProduct === null || selectedProduct=== []){
          this.setState({
            formOptions: [],
            limitedFormsLoaded:false    
          })
        }
      }else{
        
        this.loadProduct(selectedProduct, action)
      }
     
    }
    handleRegionChange = selectedRegion=>{
      this.setState({selectedRegion})
    }

    handleAtcChange = (selectedAtc, action=false)=>{
      if(this.state.selectedAtc !== null){
        if(this.state.selectedAtc === "" || this.state.selectedAtc.length <= maxOptions){
          this.setState({selectedAtc})
        }
        if(action.action ==="remove-value" || action.action ==="pop-value"){
          this.setState({selectedAtc})
        }
      }else{
        this.setState({selectedAtc})
      }
      this.loadFormOptions(this.state.selectedProduct,this.state.selectedSubstance,selectedAtc);
    }

    handleFormChange=(selectedForm, action=false)=>{

      if(this.state.selectedForm !== null){
        if(this.state.selectedForm === ""){
          // this.setState({
          //   selectedForm:selectedForm
          // })
          this.setState({
            formPlaceHolderClass:"full_width"
          })
          
        }else if(action.action ==="remove-value" || action.action ==="pop-value" || action.action === "clear"){
          
          if(this.state.selectedForm.length === 1){
            this.setState({
              formPlaceHolderClass:"full_width only_prod"
            })
          }
        }
      }
      this.setState({
        selectedForm
      })
      
    }


     
    loadFormOptions=(prod="", subs="", atc="", option="")=>{      
      let searchFormObject= {}
      searchFormObject = {
        selectedProduct:prod,
        selectedSubstance : subs,
        selectedAtc: atc
      }
      let formObject = JSON.stringify(searchFormObject);
      let formArraySet = this.state.formOptions;

      axios.post(`${API_URL}getFormsForProd`, {
      formObject: formObject
      },{
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token')
        }           
      }).then(response=>{
        let resultArray = response.data.data;
        let unique =  [...new Map(resultArray.map(item => [item['value'], item])).values()];//make the array unique and spread
        this.setState({
          formOptions: [...unique],
          limitedFormsLoaded:true    
        })
        
      }).catch( (err) => {
        console.log(err)
      })
      
    }

    loadSubstance=(selectedSubstance, action)=>{
      if(action.action === "select-option"){
           
        if(this.state.selectedAtc === ""){
          let atcArray = [];
          selectedSubstance.forEach(element => {
            let atcObj = {value: element.atc, label: element.atc}
            atcArray.push(atcObj)
          });
          this.handleAtcChange(atcArray)          
        }else{          
          let omitAtcs = false
          if(this.state.selectedAtc === null){
            omitAtcs = true;            
          }
          let atcArray = [];
          
          let optionAlreadyIn = false;
          if(!omitAtcs){
            this.state.selectedAtc.forEach(element => {
              let atcObj = {value: element.value, label: element.label}
              atcArray.push(atcObj)
              if(action.option.atc === element.value){
                optionAlreadyIn = true;
              }
            });
          }
          if(optionAlreadyIn === false){
            let atcObj1 = {value: action.option.atc, label: action.option.atc}
            atcArray.push(atcObj1)
          }
          this.handleAtcChange(atcArray)
        }
      }
      this.loadFormOptions(this.state.selectedProduct,selectedSubstance,this.state.selectedAtc);
      this.setState({ 
        selectedSubstance:selectedSubstance, 
        subsTypeToSelectText: "full_width"
      });
    }
    handleSubstanceChange = (selectedSubstance, action=false)=>{ 
      if(this.state.selectedSubstance !== null){
        if(this.state.selectedSubstance === "" || this.state.selectedSubstance.length <= maxOptions){
          this.loadSubstance(selectedSubstance,action)          
        }
        if(action.action ==="remove-value" || action.action ==="pop-value"){
          this.setState({selectedSubstance})
        }
      }else{
        this.loadSubstance(selectedSubstance,action)
      }
    }
    handleTherapyGroup=selectedTherapyGroup=>{      
      this.setState({
        selectedTherapyGroup:selectedTherapyGroup,
        disabledOption:(selectedTherapyGroup===null)? false:true
      })

    }
    handleHealthCare=selectedHealthCare=>{
      this.setState({selectedHealthCare})
    }
    handleSubsRegion=selectedSubsRegion=>{
      
      this.setState({selectedSubsRegion})
    }
    handleSubsAgeGroup=selectedSubsAgeGroup=>{
      this.setState({selectedSubsAgeGroup})
    }
    handleSubsGender=selectedSubsGender=>{
      this.setState({selectedSubsGender})
    }
    handleSubsMonths=selectedSubsMonths=>{
      this.setState({selectedSubsMonths})
    }
    handleSubstYearList=selectedYearsList2=>{
      this.setState({selectedYearsList2})
    }
    handleSubstanceChange2=selectedSubstance2=>{
      this.setState({selectedSubstance2})
    }
    
    loadRegions=()=>{
      axios.post(`${API_URL}allRegion`,
      {
        token:localStorage.getItem('token')           
      }).then(response=>{
        this.setState({
          allRegion:[...response.data.data]
        })
      })

    }
    
    loadSpecializations=()=>{
      axios.post(`${API_URL}getSpecializations`,
      {
        token:localStorage.getItem('token')           
      }).then(response=>{
        this.setState({
          specializations:[...response.data.data]
        })
      })
    }
    loadAgeGroups=()=>{
      axios.post(`${API_URL}getAgeGroup`,
      {
        token:localStorage.getItem('token')           
      }).then(response=>{
        this.setState({
          ageGroups:[...AGE_GROUP_SUBSTITUTION,...response.data.data]
        })
      })
    }
    loadYears=()=>{
      
      axios.post(`${API_URL}getYearList`,{
        token:localStorage.getItem('token')
      }).then(response=>{       
        this.setState({
          yearsList:[...response.data.data]
        })
      })
    }
    loadSubstYear=()=>{
      
      axios.post(`${API_URL}getSubstYearList`,
      {
        token:localStorage.getItem('token')
      }).then(response=>{
        this.setState({
          substYearList:[this.state.substYearList[0],...response.data.data]
        })
      })
    }
    loadTherapyGroup=()=>{
      axios.post(`${API_URL}getTherapyGroup`,
      {
        token:localStorage.getItem('token')          
      }).then(response=>{
        this.setState({
          therapyGroup:response.data.data
        })
      })
    }
   
    handelLogout=()=>{
      localStorage.removeItem('username');
      localStorage.removeItem('token');
      localStorage.removeItem('drugstatsSearchTOne');
      localStorage.removeItem('drugstatsSearchTTwo');
      this.props.MainContext.updateContext({}, "","NOT_LOGGED_IN",{},{});
      this.setState({
        user:{},
        loggedInStatus : 'NOT_LOGGED_IN'
      })
      document.location.href="/";

    }
    toggleClass = () => {
        const currentState = this.state.active;
        this.setState({ active: !currentState});
        //this.resizeSecondTable();
           
     }
     toggleInfoDivClass=()=>{

        const currentState = this.state.infoDivactive;
        this.setState({ infoDivactive: !currentState, rightPanClicked:!this.state.rightPanClicked});
        //this.resizeSecondTable('from_right_panel');
        
      }
    getAsyncOptions(valInput, type) {
        let inputValue = valInput.toLowerCase();
        if(type === "product"){
          if(inputValue.length){
            axios.post(`${API_URL}product`,{product:inputValue}).then(response=>{              
              products = response.data.data
              
            })
            const filtered = _.filter(products, o =>
                _.startsWith(_.toLower(o.label), _.toLower(inputValue))
            );
            return filtered.slice(0, 10);
            
          }


        }
        if(type === "form"){
          if(inputValue.length){
            // formOptions:response.data.data,
            //   limitedFormsLoaded:true    

            if(this.state.limitedFormsLoaded){
              const filtered = _.filter(this.state.formOptions, o =>
                _.startsWith(_.toLower(o.label), _.toLower(inputValue))
              );
              return filtered.slice(0, 10);

            }else{
              axios.post(`${API_URL}form`,{form:inputValue}).then(response=>{              
                form = response.data.data
                
              })
              const filtered = _.filter(form, o =>
                  _.startsWith(_.toLower(o.label), _.toLower(inputValue))
              );
              return filtered;
            }
            
            
          }


        }
    
        if(type === "substance"){
          //API_HEADER
          if(inputValue.length){
            axios.post(`${API_URL}substance`,{substance:inputValue}).then(response=>{              
              substance = response.data.data
            })
           
            const filtered = _.filter(substance, o =>
              _.startsWith(_.toLower(o.label), _.toLower(inputValue))
            );
            return filtered;
           
          }
        }
    
       
        if(type === "atc"){  
          //let url = `${API_URL}atc?atc=${inputValue}`;
          if(inputValue.length){
            axios.post(`${API_URL}atc`, {
              atc: inputValue
            },{
              headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
              }           
            }).then(response=>{
              atc = response.data.data
            })
            const filtered = _.filter(atc, o =>
                  _.startsWith(_.toLower(o.label), _.toLower(inputValue))
                );
            return filtered;
            
          }
        }
        if(type === "region"){  
          
          if(inputValue.length){ 
            const filtered = _.filter(this.state.allRegion, o =>
              _.startsWith(_.toLower(o.label), _.toLower(inputValue))
            );
            return filtered;
          }
        } 
        
      }

     
    componentDidUpdate(prevProps, prevState){
      let widthOfInput = document.getElementById("big_input_box").offsetWidth;
      //console.log(widthOfInput)
      
      if(widthOfInput<300){
        inputBoxSpace = 8 
      }else if(widthOfInput<360 && widthOfInput>300){
        inputBoxSpace = 12 
      }else if(widthOfInput<396 && widthOfInput>=360){
        inputBoxSpace = 15 
      }else if(widthOfInput>=396){
        inputBoxSpace = 20 
      }
      
      
      AGE_GROUP_SUBSTITUTION = [
        { value: '0-subs', label: '0-18'+Array(inputBoxSpace).fill('\xa0').join(' ') +'Only for Pres/Prod'},
        { value: '1-subs', label: '19+ '+Array(inputBoxSpace).fill('\xa0').join(' ') +'Only for Pres/Prod'  }
      ];
     
      
    }
    componentWillMount(){
      if(this.state.loggedInStatus === 'NOT_LOGGED_IN'){
          document.location.href="/";
      }
    }
    componentDidMount(){
        this.props.MainContext.updateContext(this.state.user, this.state.token, 'LOGGED_IN', localStorage.getItem('drugstatsSearchTOne'),localStorage.getItem('drugstatsSearchTTwo') );
        this.props.DataContext.updateData({}, 0, 0, 'dashboard');

        this.loadYears();
        this.loadSubstYear();
        this.loadTherapyGroup();
        this.loadAgeGroups();
        this.loadSavedSearch();
        this.loadSpecializations();
        this.loadRegions();
        //document.addEventListener('click', this.globalClickHandler)

        axios.post(`${API_URL}auth/checkLogin`, {
          token: localStorage.getItem('token')
        }).then(response => {

          
          if(response.status === 200 && (Object.keys(response.data.data)).length >0){
            if(Number(response.data.data.tutorial_active) ===1 && Number(response.data.data.seen_once) === 0){
              tutorialReady = 1;
            }
            this.setState({
                tutorialActive: response.data.data.tutorial_active,
                seenOnce: response.data.data.seen_once
            })
          }
        }).catch((error) => {
          //document.location.href="/";
          this.setState({
            loggedInStatus: 'NOT_LOGGED_IN'
          })
        })        
    }

    
    render() {
      const { updateInfo,activeSaveSearch,veryLargeData, dropdownOpen, showSpinner, downLoadActive,emptyOne,emptyTwo,fullEmpty,contractInfo,selectedToDate,selectedFromDate,savedSearches,slectedTab,clickedSearch,searchTermOne,searchTermTwo,stepsEnabled, steps,allCodeAccess, formAlert,currentStep, tutorialActive,seenOnce,tutorialStarted, autoTutorial,tutorial_active, seen_once} = this.state;        
      let mainInfoDivClass= ""
      if(this.state.active && this.state.infoDivactive){
        mainInfoDivClass = "main_info_div";
      }else if(this.state.active === false && this.state.infoDivactive){
        mainInfoDivClass = "main_info_div_toogled_one";
      }else if(this.state.active === false && this.state.infoDivactive===false){
        mainInfoDivClass = "main_info_div_toogled";
      }else if(this.state.active && this.state.infoDivactive===false){
        mainInfoDivClass = "main_info_div_toogled_two";
      }
      let formSelector = "API"
      //console.log(this.state.formOptions, this.state.limitedFormsLoaded)
      
      
      if(this.state.selectedProduct !=="" ){
        if(this.state.selectedProduct !==null){
          formSelector = "LIMITED_OPTION"
        }
      }
      //console.log(formSelector)
      // this.state.selectedSubstance, this.state.selectedAtc)

      let tablist = "";
      let renderSavedSearch = "";
      let tabContents = "";      
      let alertModalHeader = "";
      let alertModalBody = "";
      let downloadButton = "";
      let dropdownMenu = "";
      let helpLink = "";
      
        helpLink = (
          <NavItem className="navitem-border navitem_responsive">
              <NavLink className="navlink-text" href="#" onClick={this.showHelpModal}>{ question } Help</NavLink>
          </NavItem>
        )
      

      if(this.state.slectedTab === 1){      
        dropdownMenu = (
          <DropdownMenu>
                <DropdownItem onClick={this.startDownloadTwo}  style={{textAlign:"initial"}}>Export view</DropdownItem> 
                <DropdownItem divider />
                <DropdownItem onClick={this.facilityPercentage} style={{textAlign:"initial"}}>Facility percentage</DropdownItem>                 
          </DropdownMenu>
        )
      }else{
        dropdownMenu = (
          <DropdownMenu>
                <DropdownItem onClick={this.startDownloadOne} style={{textAlign:"initial"}}>Export view</DropdownItem>                
          </DropdownMenu>
        )
      }

      if((downLoadActive && !this.state.emptyOne) || (downLoadActive && !this.state.emptyTwo)){
        if(showSpinner){
          downloadButton =(
            <div  style={downLoadStyle.spinning}>{spinner} Download</div>  
          )  
        }else{
          downloadButton = (            
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {download} Download
              </DropdownToggle>
                {dropdownMenu}
            </UncontrolledDropdown>
          )
        }
        
      }else{
        downloadButton = (
          <div  style={downLoadStyle.inactive}>{download} Download</div>
        )
      }
      let strings1 = "<div>";
      if (savedSearches === undefined || savedSearches.length === 0) {
        renderSavedSearch=(
          <div className="no_saved_search">
            No saved search exists.
          </div>
        )
      }else{
      renderSavedSearch = (  
        <div>{
          
            savedSearches.map((suggestion, index) => {
               return (
                <div key={index} id={index} className="single_search_container" >
                  <div className="single_search_title"><span className="wrap_title" style={{"width":"95%", "display":"inline-block"}}>{suggestion['searchTitle']}</span><span style={{"width":"95%", "display":"inline-block"}} onClick={(e)=>this.removeThisSearch(e, suggestion['searchId'])} style={{"width":"5%"}}>{trash}</span></div>
                  <div onClick={(e)=>this.loadThisSearch(e, index,suggestion['searchId'])}> 
                    <div className={!suggestion.hasOwnProperty("selectedSubstance") ? "error-text-hide": "show-selected-item"}><span>Substance: </span><span >{suggestion['selectedSubstance']}</span></div>
                    <div className={!suggestion.hasOwnProperty("selectedProduct")? "error-text-hide": "show-selected-item"}><span >Product: </span><span >{suggestion['selectedProduct']}</span></div>
                    <div className={!suggestion.hasOwnProperty("selectedAtc") ? "error-text-hide": "show-selected-item"}><span>Atc: </span><span >{suggestion['selectedAtc']}</span></div>
                    <div className={!suggestion.hasOwnProperty("selectedForm") ? "error-text-hide": "show-selected-item"}><span>Form: </span><span >{suggestion['selectedForm']}</span></div>
                    <div className={!suggestion.hasOwnProperty("selectedYearsList") ? "error-text-hide": "show-selected-item"}><span>Year: </span><span >{suggestion['selectedYearsList']}</span></div>
                    <div className={!suggestion.hasOwnProperty("selectedSubsMonths") ? "error-text-hide": "show-selected-item"}><span >Months: </span><span >{suggestion['selectedSubsMonths']}</span></div>
                    <div  className={!suggestion.hasOwnProperty("selectedRegion") ? "error-text-hide": "show-selected-item"}><span>Region: </span><span >{suggestion['selectedRegion']}</span></div>
                    <div className={!suggestion.hasOwnProperty("selectedGender") ? "error-text-hide": "show-selected-item"}><span>Gender: </span><span >{suggestion['selectedGender']}</span></div>
                    <div  className={!suggestion.hasOwnProperty("selectedAgeGroup") ? "error-text-hide": "show-selected-item"}><span>Age group: </span><span >{suggestion['selectedAgeGroup']}</span></div>
                    <div  className={!suggestion.hasOwnProperty("selectedTherapyGroup")  ? "error-text-hide": "show-selected-item"}><span>Therapy group: </span><span>{suggestion['selectedTherapyGroup']}</span></div>
                    <div  className={!suggestion.hasOwnProperty("selectedHealthCare") ? "error-text-hide": "show-selected-item"}><span>Health care: </span><span>{suggestion['selectedHealthCare']}</span></div>
                  </div>
                  
                </div>
               )
            })
          } 
        </div>
      ) 
    }
      let helpModalBody = "";
      let helpModalHeader = "";
      if(this.state.helpModal){
        helpModalHeader = "Use of Drugstats";
        helpModalBody =  <div><Help/></div>
      }
      if(veryLargeData){
        alertModalHeader = "Very large result set to export!";
        alertModalBody =  <div className="form_alert"><p>You must add more search parameters.</p></div>
      }
      
      if(fullEmpty){
        alertModalHeader = "Empty search form!";
        alertModalBody =  <div className="form_alert"><p>You must type in at least one search parameter.</p></div>
      }else{
        if(emptyOne && slectedTab ===0 && !fullEmpty){
          alertModalHeader = "Empty search parameters for this tab!";
          alertModalBody = (
            <div className="form_alert">
                <p> You must choose at least one search parameter from these options: </p>
                <div className="option_detail">
                  <ul className="modal_option_names">
                    <li>Substance</li>
                    <li>ATC code</li>
                    <li>Year</li>                            
                    <li>Gender</li>
                    <li>Age group</li>
                    <li>Region</li>
                    <li>Therapy group</li>

                  </ul>
                </div>
              </div>
          )
        }
        if(emptyTwo && slectedTab ===1 && !fullEmpty){
          alertModalHeader = "Empty search parameters for this tab!";
          alertModalBody = (
            <div className="form_alert">
                <p> You must choose at least one search parameter from these options: </p>
                    <div className="option_detail">
                      <ul className="modal_option_names">
                        <li>Product</li>
                        <li>Substance</li>
                        <li>ATC code</li>
                        <li>Year</li>
                        <li>Month</li>
                        <li>Gender</li>
                        <li>Age group</li>
                        <li>Region</li>
                        <li>Healthcare facility</li>
                      </ul>
                    </div>
              </div>
          )
        }
      }
      
        
       
        tablist = (
          <TabList>
            <Tab id='substance' onClick={(e)=>this.tabSelected(e, 'substance')}>
                <div id="substance_tab_div">
                    <span className="tab_header">PRESCRIPTIONS / SUBSTANCE</span>
                    <span className="tab_header_icon">{ fileContract }</span>
                </div>
            </Tab>
            <Tab id='product' onClick={(e)=>this.tabSelected(e, 'product')}>
            <div id="product_tab_div">
                <span className="tab_header">PRESCRIPTIONS / PRODUCTS</span>
                <span className="tab_header_icon">{pills}</span>
                </div>                
            </Tab>          
          </TabList>
          
          
        )
        tabContents=(
          <div className="tab_contents">
              <TabPanel>
                  <Substance  searchTerm={searchTermOne} clickedSearch = {clickedSearch}/>
              </TabPanel>
              <TabPanel>
              <Product  searchTerm={searchTermTwo}  clickedSearch = {clickedSearch}/>                  
              </TabPanel>              
          </div>
        )
        let formDropdown = ""
        //this.state.formOptions
        if(this.state.limitedFormsLoaded){
          formDropdown = (
            <div className= {this.state.formPlaceHolderClass} data-id="Only for Pres/Prod">
            
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{pills}</div></InputGroupAddon>
                    <div className="big_input plugin"   data-cy="formInput" >
                      <Select styles={customStyles}
                        isMulti
                        placeholder ="Form"
                        
                        onFocus={()=>this.changePlaceHolderText("Form")}
                        value={this.state.selectedForm}
                        options={this.state.formOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleFormChange}
                      />
                    </div>
                    
                </InputGroup>
            </div>
          )
        } else{

          formDropdown = (
            <div   className= {this.state.formPlaceHolderClass}  data-id={this.state.formPlaceHolderText} >
              
                  <InputGroup size="sm">
                      <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{pills}</div></InputGroupAddon>
                      <div className="big_input plugin" data-cy="formInput" >
                      {/* <AsyncSelect styles={this.state.disabledOption? "": customStyles} */}
                      <AsyncSelect  styles={customStyles}
                      isMulti
                      //isDisabled={this.state.disabledOption}
                      cacheOptions
                      value={this.state.selectedForm}
                      onFocus={()=>this.changePlaceHolderText("Form")}
                      placeholder ="Form"
                      components={{ DropdownIndicator:() => null }}
                      noOptionsMessage={() => null}
                      //loadOptions={this.getAsyncOptions}
                      onChange={this.handleFormChange}
                      loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "form")}
                      />
                      </div>
                  </InputGroup>
              </div>
          )
        }
        

        
        let searchForm2 =""
          
          searchForm2 = (
           
              <div className="inputFields">
                <div  className={(this.state.prodPlaceHolder&& (this.state.selectedProduct===null || this.state.selectedProduct==="" ))?"full_width type_to_select":"full_width"} data-id="Type to select">
                
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"> <div className="form_lablel_img">{tablets}</div> </InputGroupAddon>
                    <div id="big_input_box" className="big_input plugin"   data-cy="productInput" >
                    <AsyncSelect  styles={customStyles} 
                    isMulti
                    value={this.state.selectedProduct}
                    cacheOptions
                    onFocus={()=>this.changePlaceHolderText("Product")}
                    // placeholder ={this.state.prodPlaceHolder?"Product"+Array(inputBoxSpace ).fill('\xa0').join(' ')+"Type to select":"Product"}
                    placeholder="Product" 
                    components={{ DropdownIndicator:() => null }}
                    //loadOptions={this.getAsyncOptions}
                    noOptionsMessage={() => null}
                    onChange={this.handleProductChange}
                    loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "product")}
                    />
                    </div>
                </InputGroup>
            </div>
                <div className={(this.state.subsPlaceHolder&& (this.state.selectedSubstance===null || this.state.selectedSubstance==="" ))?"full_width type_to_select":"full_width"}     data-id="Type to select" id="substance_input">
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"><img src={molecule} alt="molecule"  className="form_lablel_img"/></InputGroupAddon>
                    <div className="big_input plugin"  data-cy="substanceInput">
                    {/* <AsyncSelect styles={this.state.disabledOption? "": customStyles} */}
                    <AsyncSelect  styles={customStyles}
                    isMulti
                    // isDisabled={this.state.disabledOption}
                    value={this.state.selectedSubstance}
                    cacheOptions
                    placeholder ="Substance"
                    onFocus={()=>this.changePlaceHolderText("Substance")}
                    components={{ DropdownIndicator:() => null }}
                    //loadOptions={this.getAsyncOptions}
                    noOptionsMessage={() => null}
                    onChange={this.handleSubstanceChange}
                    loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "substance")}
                    />
                    </div>
                </InputGroup>
            </div>
            <div className={(this.state.atcPlaceHolder&& (this.state.selectedAtc===null || this.state.selectedAtc==="" ))?"full_width type_to_select":"full_width"}     data-id="Type to select">
            
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"><img src={atcIcon} alt="molecule"  className="form_lablel_img"/></InputGroupAddon>
                    <div className="big_input plugin"  data-cy="atcInput">
                    {/* <AsyncSelect styles={this.state.disabledOption? "": customStyles} */}
                    <AsyncSelect  styles={customStyles}
                    isMulti
                    //isDisabled={this.state.disabledOption}
                    cacheOptions
                    value={this.state.selectedAtc}
                    onFocus={()=>this.changePlaceHolderText("ATC")}
                    placeholder ="ATC"
                    components={{ DropdownIndicator:() => null }}
                    noOptionsMessage={() => null}
                    //loadOptions={this.getAsyncOptions}
                    onChange={this.handleAtcChange}
                    loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "atc")}
                    />
                    </div>
                </InputGroup>
            </div>
            {formDropdown}
            <div className="full_width">
            
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{calendarDay}</div></InputGroupAddon>
                    <div className="big_input plugin"  data-cy="yearInput">
                      <Select styles={customStyles}
                        isMulti
                        placeholder ="Year"
                        name="year"
                        onFocus={()=>this.changePlaceHolderText("Year")}
                        value={this.state.selectedYearsList}
                        options={this.state.yearsList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleYearsList}
                      />
                    </div>
                    
                </InputGroup>
            </div>
            <div className={(this.state.selectedSubsMonths===null || this.state.selectedSubsMonths==="" )?"full_width only_prod":"full_width"}   data-id="Only for Pres/Prod">
                
                    <InputGroup size="sm">
                      <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{calendarDay}</div></InputGroupAddon>
                        <div className="big_input plugin"  data-cy="monthInput">
                          <Select styles={customStyles}
                            isMulti
                            placeholder ="Month"
                            name="month"
                            onFocus={()=>this.changePlaceHolderText("Month")}
                            value={this.state.selectedSubsMonths}
                            options={MONTHS}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleSubsMonths}
                          />
                        </div>
                        
                    </InputGroup>
            </div>
            <div  data-id="Type to select" className={(this.state.regionPlaceHolder&& (this.state.selectedRegion===null || this.state.selectedRegion==="" ))?"full_width type_to_select":"full_width"}>
            
              <InputGroup size="sm">
                  <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{area}</div></InputGroupAddon>
                  <div className="big_input plugin" data-cy="regionInput">
                  <AsyncSelect styles={customStyles} 
                  isMulti
                  cacheOptions
                  value={this.state.selectedRegion}
                  onFocus={()=>this.changePlaceHolderText("Region")}
                  components={{ DropdownIndicator:() => null }}
                  placeholder ="Region"
                  noOptionsMessage={() => null}
                  //loadOptions={this.getAsyncOptions}
                  onChange={this.handleRegionChange}
                  loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "region")}

                  
                  />
                  </div>
              </InputGroup>

              
          
          
            </div>
            <div className="full_width">
            
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{gender}</div></InputGroupAddon>
                    <div className="big_input plugin"  data-cy="genderInput">
                    <Select styles={customStyles}
                        isMulti
                        value={this.state.selectedGender}
                        onFocus={()=>this.changePlaceHolderText("Gender")}
                        placeholder ="Gender"
                        options={[
                          // { value: 'Both', label: 'Both'},
                          { value: '0', label: 'Kvinnor'},
                          { value: '1', label: 'Män'}]}                        
                        noOptionsMessage= {() => "Type to select"}
                        onChange={this.handleGender}
                      />
                    </div>
                    
                </InputGroup>
            </div>
            
            <div className="full_width">
            
                <InputGroup size="sm">
                  <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{AgeGroup}</div></InputGroupAddon>
                    <div className="big_input plugin"   data-cy="ageGroupInput">
                    
                    <Select styles={customStyleLast}
                        isMulti
                        value={this.state.selectedAgeGroup}
                        options={this.state.ageGroups}
                        placeholder = "Age group"
                        //placeholder ={this.state.ageGroupHolder?"Age group":"Age group"} 
                        onFocus={()=>this.changePlaceHolderText("AgeGroup")} 
                        noOptionsMessage= {() => "Type to select"}
                        onChange={this.handleAgeGroup}
                      />
                    </div>
                </InputGroup>
            </div>
           
            <div className="full_width">
            
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{group}</div></InputGroupAddon>
                    
                    <div className="big_input plugin" data-cy="therapyInput">
                    
                    <Select 
                       styles={customStyleLast}  
                        isClearable={true}
                        placeholder ="Therapy group"
                        value={this.state.selectedTherapyGroup} 
                        options={this.state.therapyGroup}
                        onFocus={()=>this.changePlaceHolderText("TherapyGroup")}
                        
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleTherapyGroup}
                      />
                    </div>
                </InputGroup>
              </div>
                
                <div className={(this.state.selectedHealthCare===null || this.state.selectedHealthCare==="" )?"full_width only_prod":"full_width"}  data-id="Only for Pres/Prod">
                
                    <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{medical}</div></InputGroupAddon>
                        
                        <div className="big_input plugin" data-cy="hcfInput">
                        
                        <Select
                            styles={customStyleLast} 
                            isClearable={true}
                            placeholder ="Healthcare facility"
                            //placeholder ={"Healthcare facility     Only for Pres/Prod"}
                            //placeholder ={this.state.genderHolder?"Healthcare facility &emsp; &emsp; &emsp; &emsp; Only for Pres/Prod":"Healthcare facility &emsp; &emsp; &emsp; &emsp; Only for Pres/Prod"}  
                            name="health care"
                            onFocus={()=>this.changePlaceHolderText("HealthCare")}
                            value={this.state.selectedHealthCare}
                            //handleHealthCare=selectedHealthCare=>{
                            options={this.state.specializations}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleHealthCare}
                          />
                        </div>
                    </InputGroup>
                </div>
                <div className="checkbox_container">
                  <div className="half_width_chk first_item form_text">
                    <label><Input id="per_month_only_chk" type="checkbox" defaultChecked={this.state.perMonth} onChange={this.perMonthOnly} /><span  className="checkboxText" >Prescriptions presented per month</span></label>
                  </div>
                <div className="half_width_chk last_item form_text">
                <label><Input id="region_wise_chk" type="checkbox" defaultChecked={this.state.regionWise} onChange={this.regionWiseData} /><span  className="checkboxText">Region wise prescriptions &nbsp; &nbsp;</span></label>
                </div>

                </div>
                <div >
                  <div className="half_width first_item form_text">                                
                      <InputGroup size="sm">
                        <span className="abbreviation">Pop. — Population</span>
                        <span className="abbreviation">Pa. c. — Patient count</span>
                      </InputGroup>
                  </div>
                  <div className="half_width last_item form_text">                               
                      <InputGroup size="sm">
                      <span className="abbreviation_right">Pr. c. — Prescription count</span>
                      <span className="abbreviation_right">DDD. c. — Defined Daily Dose count</span>
                      </InputGroup>
                  </div>

                </div>
            </div>                
             
          )
        let saveButton = ""   
        if(clickedSearch){
          saveButton = <SaveSearchForm saveAndReloadStrings={(e,sName)=>this.saveAndReloadStrings(e, sName)}/>
        }else{
          saveButton = <button type="button"  id="save_search" className="form-button-inactive" >Save</button>
        }
          

        return (
            <React.Fragment>
                <div>
                    <div className="main_container" id="vh" style={{height:"100vh"}}>
                    <div className="drop_down_btn"></div>
                        {/* <Navigation navProps={this.state} /> */}
                        <div id="top_nav">
                        <div  id="navigation" className="navigation">
                        <Navbar color="light" light expand="md" className="slim_nav" >
                            <NavbarBrand className="nav-logo-holder" href="/">
                                <img src={logo} alt="Logo" />
                            </NavbarBrand>
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.isOpen} navbar>
{
//downLoadActive
}
                            <Nav className="ml-auto">
                                {helpLink}
                                {downloadButton}
                                <NavItem className="navitem-border navitem_responsive">
                                  <NavLink className="navlink-text" href="#" onClick={this.handelLogout}>{ signout } Logout</NavLink>
                                </NavItem>
                            </Nav>
                            </Collapse>
                        </Navbar>
                        
                        </div>
                        
                    </div>
                        <div className="dashboard" id="dashboardWindow" >
                        <div id="empty_selector"></div>
                        <div id="search_pannel" onMouseOut={this.mouseOutLeftPannel} onMouseOver={this.hoverEffectArraow} onClick={this.toogleLeftPannel} className={this.state.active ? 'grid_item1': 'grid_item1_toggled'} >
                        <div id="search_button" >
                            <div id="walkthrough_search_div" className="walkthrough_search_div"> 
                            <div id="search_button_effect" className={this.state.active ? 'search-button': 'search-button_toggled'} onClick={this.toggleClass}>{ this.state.active ? LeftArraow:search}</div>
                        </div>                    
                        </div>
                            <div className={this.state.active ? 'show_pannel': 'hide_pannel'}>
                                <div className={this.state.active ? 'search_form': 'search_form_toogled'}>
                                  <Form id="search_form" autoComplete="off">
                                    {searchForm2}
                                    <div id="form_buttons" className={this.state.active ? 'buttons': 'buttons_toggled'}>
                                      <button type="button" id="submit_form" className="form-button"  onClick={this.searchItems}>Search</button>
                                      <button type="button" id="reset_form" className="form-button" onClick={this.resetForm}>Reset</button>
                                      {saveButton}
                                    </div>
                                  </Form>               
                                </div>
                            </div>
                        </div>
                        <main id="main">
                          <div className={mainInfoDivClass}>
                              <div className="tab_container" id="tab_container">
                                <Tabs className="tabs_responsive">
                                    <div className="tab_list" id='walkthrough_salesval'>
                                        {tablist}
                                    </div>
                                    {tabContents}
                                </Tabs>
                              </div>
                          </div>
                        </main>
                        <aside id="aside">
                          <div id="rightSideInfo" className={this.state.infoDivactive ? 'update_info zindex_2': 'update_info_toggled zindex_2'}>
                            <div  className={this.state.infoDivactive ? 'contract_status_container': 'contract_status_container_toogled'}>
                              <div className="infotag" onClick={this.toggleInfoDivClass} ></div>                    
                              <ol className={this.state.infoDivactive ? 'status-list': 'status-list_toggled'} >
                                  <li className="update_text"> Saved Searches</li>
                                  <div className="savedSearchDiv">
                                    {renderSavedSearch}
                                  </div>
                                
                              </ol>   
                            </div>
                          </div>
                        </aside>
                        </div><footer className="footer" id="footer">Bonnier Pharma Insights AB ©</footer></div>
                        <div style={{width:'100vw'}}>
                          <Modal style=  {{maxWidth: '500px', width: '500px'}}  isOpen={this.state.modal} toggle={this.toogleModal}>
                            <ModalHeader  toggle={this.toogleModal} charCode="x" className="modal_title">{alertModalHeader}</ModalHeader>
                            {alertModalBody}
                            <ModalFooter> 
                              <button onClick={this.toogleModal} type="button" className="form-button">Ok</button>                 
                            </ModalFooter>
                          </Modal>
                        </div>
                        <div style={{width:'100vw'}}>
                          <Modal style=  {{maxWidth: '1000px', width: '1000px'}}  isOpen={this.state.helpModal} toggle={this.showHelpModal}>
                            <ModalHeader  toggle={this.showHelpModal} charCode="x" className="modal_title">{helpModalHeader}</ModalHeader>
                            {helpModalBody}
                            <ModalFooter> 
                              <button onClick={this.showHelpModal} type="button" className="form-button">Ok</button>                 
                            </ModalFooter>
                          </Modal>
                        </div>
                        <div style={{width:'50vw'}}>
                          <Modal size="lg" isOpen={this.state.unauthorizedModal}>
                            <ModalHeader  charCode="x" className="modal_title">Session Missmatched!</ModalHeader>
                            <ModalBody className="modalBody">
                            Your session has expired. For your safety, you have been logged out and must sign in again to continue.
                            </ModalBody>
                            <ModalFooter> 
                              <button onClick={this.showTimeOut} type="button" className="form-button">Ok</button>                 
                            </ModalFooter>
                          </Modal>
                        </div>
                        
                    </div>
            </React.Fragment>
        );
    }
}

const withContext = (Component) => {
   
      return(props)=>(
        <MainContext.Consumer>
          {(MainContext) => (
              <DataContext.Consumer>
                {(DataContext) => (
                    <Component {...props} MainContext={MainContext} DataContext={DataContext} />
                )}
              </DataContext.Consumer>
          )}
        </MainContext.Consumer>
      )
    
 }
 
 export default withContext(Dashboard);