import React,{createContext,Component} from 'react';

export const MainContext = createContext();

class DrugstatsContextProvider extends Component {
    state = { 
        loggedInStatus: "",
        token: "",
        user:{
            username:"",
            userCompany:""
        },
        searchTermOne:localStorage.getItem('drugstatsSearchTOne'),
        searchTermTwo:localStorage.getItem('drugstatsSearchTTwo')
        
    }
    componentDidUpdate(prevProps, prevState) {
        //console.log(prevState, this.state)
        // if (this.state.value !== prevState.value) {
        //   // Whatever storage mechanism you end up deciding to use.
        //   localStorage.setItem("parentValueKey")
        // }
    }
    updateContext=(user, token, loggedInStatus)=>{
        this.setState({
            loggedInStatus:loggedInStatus,
            user:user,
            token:token

        })
    }
    render() { 
       //console.log(this.state)
        return (  
            <MainContext.Provider value={{...this.state,updateContext:this.updateContext}}>
                {this.props.children}
            </MainContext.Provider>
        );
    }
}
 
export default DrugstatsContextProvider;