// export const API_URL = `http://localhost:8888/`;

// export const API_EXPORT_URL = `http://localhost:8888/`;


//export const API_EXPORT_URL = `http://api.drugstats.test.dev.pharmainsights.se/`;

//export const API_URL = `http://api.drugstats.test.dev.pharmainsights.se/`;

export const API_EXPORT_URL = `https://api.drugstats.se/`;

export const API_URL = `https://api.drugstats.se/`;
