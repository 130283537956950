import React, { Component } from 'react';
import { ModalBody,Badge} from 'reactstrap';
import searchingImage from '../../images/searching.png'
import savedSearch from '../../images/saved_search.png'
import tabsImage from '../../images/tabs.png'

import totalChart from '../../images/totalChart.png'
import totals from '../../images/totals.png'
import sorting from '../../images/sorting.png'
import exporting  from '../../images/export.png'
import indiVidualChart from '../../images/individualChart.png'
export default class Help extends Component {
    render() {
        return (
          
                <ModalBody className="modalBody">
                      <div className="help_content">
                        
                        <div style={{width:"100%"}}>
                           <p> <span className="brand">Drugstats </span> 
                            give you an overview of pharmaceutical prescriptions in relation to demographics and prescribing care facilities. In Drugstats you can find the number of prescriptions made within a certain region for a specific substance or product and also find out information of both patient groups as well as where the prescriber works. 
                            The database contains statistics from 2008 and forward, and the application allows you to filter on various parameters.
                           </p>
                        </div>                        
                        <div style={{width:"100%"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge>Searching</Badge></h5>
                                <p>
                                To perform a search, start in the search tab on the left-hand side. Enter your desired parameters into the relevant input fields or select an option from the appropriate drop-downs. Fields like Substance, 
                                Product and ATC code will automatically suggest the most relevant entries based on what you have typed. To execute the search click the Search button. 
                                </p>
                                <p>
                                <span className="brand">The "Presciptions presented per month" checkbox</span><br/>
                                    If the "Prescriptions presented per month" box is ticked you will see the prescriptions presented per month while unselecting it will show data yearly.
                                </p>

                                <p>
                                <span className="brand">The "Region wise prescriptions" checkbox</span><br/>
                                If the "Region wise prescriptions" box is ticked you will see the prescriptions presented region wise while unselecting it will show the prescriptions on national level.
                                </p>
                                
                            </div> 
                            
                            
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge>Saving your search</Badge></h5>
                                <p>
                                In Drugstats you have the opportunity to save your search. After you have performed a search the “Save” button will be activated. Saving the search will allow you to get back to the same view at a later stage. All saved searches will be displayed on the right panel afterwards, including search parameters. 
                                When clicking at a saved search the parameters will be loaded into search form and you could then alter them or just press “Search”.
                                </p>
                            </div>
                            <div style={{float: "let", display:"inline", width:"100%"}}>
                            <img  style={{display:"inline-block",height:"420px",width:"100%"}}src={savedSearch} alt="drugstats saved search" /> 
                            </div> 
                        </div>
                        <br/>
                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge>Tabs</Badge></h5>
                                <img  style={{float: "left", display:"inline", width:"100%"}} src={tabsImage} alt="drugstats tabs" />
                               
                                <p style={{float: "left", width:"100%", fontStyle:"italic", marginTop:"10px"}}> The tabs show different aspects of the data. </p>
                               
                                <p style={{float: "left", width:"100%"}}>
                                <span className="brand_italic">Prescriptions / substance</span>&nbsp; 
                                In this tab you are able to search on Substance or ATC-codes and you may select at maximum of 20 ATC-codes at a time. Age groups are based on 5 year intervals. There is a legend in a box in the search interface explaining the abbreviations. Population is retrieved from Statistiska Centalbyrån (SCB) and the rest of the data originate from Socialstyrelsens Läkemedelsdatabas. 2019 Socialstyrelsen decided not to provide DDD information any longer due to legal issues making this data only available up until 2018, 
                                as the decision was made before the release of 2018 figures. The data in this tab is updated once a year as of now.
                                    <br/>
                                <span className="brand_italic">Prescriptions / product</span>&nbsp;  
                                In this tab you are able to search on Product name as well as Substance and ATC-codes. Your search will show from which type of healthcare facility the prescription is issued. Age groups are divided into two spans only – 0-18 years or 19+. You can also select a specific healthcare facility if you like. In this tab you will see prescription count and how many of these prescriptions that have been switched to another product. i.e. the patient has been given an alternative to what was originally prescribed at pharmacy. The data in this tab is updated monthly. The data is from 2014 and forward.
                                   <br/> 

                                </p>
                            </div>                            
                        </div>

                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge>Sorting</Badge></h5>
                                
                               
                                <p style={{float: "left", width:"100%"}}>
                                By default, search results are sorted according to a logical order. You can sort to an ascending order by clicking on its corresponding header. Clicking a second time will reverse the order. Clicking a third time will reset the order.                              
                                </p>
                                <img  style={{float: "left", display:"inline", width:"100%"}} src={sorting} alt="sorting" />
                            </div>
                            <p style={{fontStyle:"italic"}}>Sorting on population in Ascending order.</p>
                        </div>

                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge>Totals and Graphs</Badge></h5>
                                
                               
                                <p style={{float: "left", width:"100%"}}>
                                    The grey boxes at the bottom end of the screen show the totals for your search criteria.                              
                                </p>

                                <div><img  style={{float: "left", display:"inline", width:"100%"}} src={totals} alt="sorting" />                                
                                </div>
                                <span style={{fontStyle:"italic"}}>Totals for your search criteria </span>
                                
                                <br/><br/>
                                
                                <p style={{float: "left", width:"100%"}}>
                                    Clicking on the "Total for search (Graph)" button in "Prescriptions / substance" tab shows the chart of patient count and prescription count over time.
                                </p>
                                <img  style={{float: "left", display:"inline", width:"100%"}} src={totalChart} alt="sorting" /><br/>

                            </div>
                            <p style={{fontStyle:"italic"}}>Total for search (Graph)</p>

                            <span className="brand_italic">Graph for individual row</span> &nbsp;
                               In the tab Prescriptions / substance you will be given a chart by clicking each row.<br/><br/> 
                            <div>
                                <img  style={{float: "left",display:"inline", width:"100%"}} src={indiVidualChart} alt="sorting" />                                
                            </div>
                                
                        </div>
                        <p style={{fontStyle:"italic"}}>Graph for each row</p>

                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge>Exporting</Badge></h5>
                                
                                <img  style={{float: "left", display:"inline", width:"100%"}} src={exporting} alt="exporting" />
                                <p style={{float: "left", width:"100%"}}>
                                All search results can be exported in Excel for your own convenience. You find the export-button on the top right hand side of the page.
                                </p>
                            </div>
                            {/* <p style={{fontStyle:"italic"}}>Sorting on Size in Ascending order.</p> */}
                        </div>
                       
                    
                   
                    

                    

                        {/* <img  style={{float: "left", display:"inline", width:"300px"}} src={contractInfo} alt="sorting" /> 
                                <p style={{fontStyle:"italic"}}>The info box</p> */}
                       
                        
                        
                    </div>
                  </ModalBody>
          
        );
    }
}
