import React, { Component } from 'react';
import { Input, Form,FormGroup, InputGroupAddon, InputGroup  } from 'reactstrap';
import { Modal, ModalHeader,ModalBody, ModalFooter} from 'reactstrap';
import {API_URL} from '../../constant/ApiLink'
import axios from 'axios'
class SaveSearchForm extends Component {
    constructor(props){
        super(props)
        this.state={
            saveSearchModal:false,
            search_title:"",
            savedSearches:[],
            epmtyTitle:false,
            longTitle:false        
        }
        this.toogleSaveSearch.bind(this)
        this.saveSearchStrings.bind(this)
        this.setSearchTitle.bind(this)
    
    }
    saveSearchStrings=(e)=>{
        console.log(e.nativeEvent.type)
        if(e.nativeEvent.type === "submit" || e.nativeEvent.type === "click"){
            let title  = this.state.search_title;
            
            if(title === ""){
                this.setState({
                 epmtyTitle:true,
                 longTitle:false
                })       
            }else{
                if(title.length<= 60){
                  this.toogleSaveSearch()
                  this.props.saveAndReloadStrings(e,title)
                }else{
                    this.setState({
                      longTitle:true,
                      epmtyTitle:false
                    })
                }
                
                
                   
                }
                e.preventDefault();
        }
        
    }
    toogleSaveSearch=()=>{
        this.setState({
          saveSearchModal:!this.state.saveSearchModal        
        })
    }
    setSearchTitle = (val)=>{
        this.setState({
          search_title:val
            //[targetField.name] : targetField.value
        });
      }
    render() {
        return (
            <div>
                <button type="button"  id="save_search" className="form-button" onClick={this.toogleSaveSearch} >Save</button>                
                <div style={{width:'50vw'}}>
                          <Modal size="lg" isOpen={this.state.saveSearchModal} >                        
                            <ModalHeader toggle={this.toogleSaveSearch} charCode="x" id="save_search" className="modal_title">Save search</ModalHeader>
                            <ModalBody className="modalBody">
                            <Form onSubmit={(e)=>this.saveSearchStrings(e)}>
                                <FormGroup>
                                  <div style={{"marginTop":"25px"}}>
                                    {this.state.epmtyTitle?<div style={{"marginTop":"15px","marginBottom":"15px"}}>Search title can not be empty</div>:""}
                                    {this.state.longTitle?<div style={{"marginTop":"15px","marginBottom":"15px"}}>Search title can not have more than 60 character</div>:""}                                  
                                      <Input type="text" name="search_title" id="search_title" value={this.state.search_title} onChange={(e) => this.setSearchTitle(e.target.value) } placeholder="Search title (Maximum 60 Character)" />                                  
                                  </div>                          
                                </FormGroup>
                              </Form>
                            </ModalBody>
                            <ModalFooter> 
                              <button onClick={(e)=>this.saveSearchStrings(e)} type="button" className="form-button">Ok</button>                 
                            </ModalFooter>                        
                          </Modal>
                        </div>
            </div>
        );
    }
}

export default SaveSearchForm;