import React,{createContext,Component} from 'react';

export const DataContext = createContext();

class DataContextProvider extends Component {
    _isMounted = false;
    constructor (props) {
        super(props)
        
        this.state = {
            dataSubstance:{},
            dataSubstanceRaw:{},
            substanceTotal:0,
            substanceInPage:0,
            requestOneSent:0,
            orderByOne:"",
            orderOne:'',
            dataProduct:{},
            dataProductRaw:{},
            productTotal:0,
            productInPage:0,
            requestTwoSent:0,
            orderByTwo:"",
            orderTwo:'',
            dataSum:{},
            dataSumLoaded:0,
            ungrouperdData:{},
            ungrouperdDataLoaded:0,
            consolidatedData:{},
            consolidationLoaded:0,
            savedSearchData:{},
            savedSearchesLoaded:0
        }
        this.updateData.bind(this)
    }
    componentDidMount(){
        this._isMounted = true;
        if (this._isMounted) {
            this.updateData =  this.updateData.bind(this)
        }
    }
  
    updateData=(data,total, inPage, from="",orderBy, order, requestSent=0)=>{
        if(from ==="dashboard"){
            this.setState({
                dataSubstance:{},
                substanceTotal:0,
                substanceInPage:0,
                requestOneSent:requestSent? requestSent:0,
                orderByOne:"",
                orderOne:'',
                dataProduct:{},
                dataProductRaw:{},
                productTotal:0,
                productInPage:0,
                requestTwoSent:requestSent? requestSent:0,
                orderByTwo:"",
                orderTwo:'',
                dataSum:{},
                dataSumLoaded:0,
                ungrouperdData:{},
                ungrouperdDataLoaded:0
            })
        }else if(from ==="substance"){
            this.setState({
                dataSubstance:data,
                substanceTotal:total,
                substanceInPage:inPage,
                requestOneSent:1,
                orderByOne:orderBy,
                orderOne:order,
            })
        }else if(from ==="substance_raw"){
            this.setState({
                dataSubstanceRaw:data,
                substanceTotal:total,
                substanceInPage:inPage,
                requestOneSent:1,
                orderByOne:orderBy,
                orderOne:order,
            })
        }else if(from ==="product_raw"){
            this.setState({
                dataProductRaw:data,
                productTotal:total,
                productInPage:inPage,
                requestTwoSent:1,
                orderByTwo:orderBy,
                orderTwo:order,
            })
        }
        else if(from ==="product"){
            this.setState({
                dataProduct:data,
                productTotal:total,
                productInPage:inPage,
                requestTwoSent:1,
                orderByTwo:orderBy,
                orderTwo:order,
            })
        }else if(from === "sumData"){
            this.setState({
                dataSum:data,
                dataSumLoaded:1
            })
        }else if(from === "ungrouperdData"){
            this.setState({
                ungrouperdData:data,
                ungrouperdDataLoaded:1
            })
        }else if(from === "consolidatedData"){
            this.setState({
                consolidatedData:data,
                consolidationLoaded:1
            })
        }else if(from === "savedSearch"){
            this.setState({
                savedSearchData:data,
                savedSearchesLoaded:1
            })
        }
        
    }
    render() { 
      
         return (  
             <DataContext.Provider value={{updateData:this.updateData, data:this.state}}>
                 {this.props.children}
             </DataContext.Provider>
         );
     }
}

export default DataContextProvider;